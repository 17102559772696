import { ajax } from '../utils/ajax'

export const moduleService = {
    /**
     * Send Get Modules Info Ajax Request.
     * @param {object} condition - The condition of query.
     * @description For search grid's result.
     */
    getAll: (condition) => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/permission`,
            {
                params: {
                    ...condition
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
    /**
     * Send Get Modules Info Ajax Request.
     * @param {object} condition - The condition of query.
     * @description For dropdownlist.
     */
    getList: (condition) => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/permission/list`,
            {
                params: {
                    ...condition
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
    /**
     * Send Get Module Info Ajax Request.
     * @param {string} id - The id of module.
     */
    get: (id) => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/permission/${id}`)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
    /**
     * Create Module Category.
     * @param {object} info - The info of module category.
     */
    createModuleCategory: (info) => {
        return ajax.post(`${process.env.REACT_APP_API_URL}/permission-category`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...info
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
    /**
     * Update Module Category.
     * @param {string} id - The id of module category.
     * @param {object} info - The info of module category.
     */
    updateModuleCategory: (id, info) => {
        return ajax.put(`${process.env.REACT_APP_API_URL}/permission-category/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...info
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
    /**
     * Create Module.
     * @param {object} info - The info of module.
     */
    createModule: (info) => {
        return ajax.post(`${process.env.REACT_APP_API_URL}/permission`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...info
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
    /**
     * Update Module.
     * @param {string} id - The id of module.
     * @param {object} info - The info of module.
     */
    updateModule: (id, info) => {
        return ajax.put(`${process.env.REACT_APP_API_URL}/permission/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...info
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
    /**
     * Delete Module.
     * @param {string} id - The id of module.
     */
    delete: (id) => {
        return ajax.delete(`${process.env.REACT_APP_API_URL}/permission/${id}`)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
};