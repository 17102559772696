import "react-app-polyfill/ie9";
import React from "react";
import { createRoot } from "react-dom/client";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { Provider } from "react-redux";
import "./index.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import moment from "moment";
import "moment/locale/zh-tw";
import { composeWithDevTools } from "redux-devtools-extension";
import NP from "number-precision";
moment.locale("zh-tw");

NP.enableBoundaryChecking(false);

// 使用 reducer 函數，建立 Store 實例，Store 會將改變狀態邏輯委託給 reducer 實作
const store = createStore(
  rootReducer,
  // Debug 工具，方便除錯與掌握 store 資料狀況
  composeWithDevTools(
    // 將 middleware 依序傳遞進 applyMiddleware, ex: applyMiddleware(middleware1, middleware2, ...)
    // 將回傳的 enhancer 函數傳遞給 createStore
    applyMiddleware(thunk)
  )
);

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
