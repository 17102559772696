import { reduce } from 'lodash';
import { roleConstants } from '../constants';
import { roleService } from '../services';
import { maskActions } from './mask.actions';
import { msgTypes, showModal } from '../utils';

export const roleActions = {
    /**
     * Get Roles Info.
     * @param {object} condition - The condition of query.
     * @description For search grid's result.
     */
    getAll(condition) {
        return dispatch => {
            dispatch({
                type: roleConstants.GET_ALL_REQUEST
            });
            roleService.getAll(condition)
                .then(
                    (roles) => {
                        dispatch({
                            type: roleConstants.GET_ALL_SUCCESS,
                            queryCondition: { ...condition },
                            roles
                        });
                    },
                    (error) => {
                        dispatch({
                            type: roleConstants.GET_ALL_FAILURE,
                            error: '讀取角色權限查詢結果失敗：' + error
                        });
                    }
                )
        }
    },
    /**
     * Reset Roles Info
     */
    resetAll() {
        return {
            type: roleConstants.RESET_ALL_SUCCESS,
        };
    },
    /**
     * Get Roles List.
     * @description For dropdownlist.
     */
    getList() {
        return dispatch => {
            dispatch({
                type: roleConstants.GET_LIST_REQUEST
            });
            roleService.getList()
                .then(
                    (roles) => {
                        dispatch({
                            type: roleConstants.GET_LIST_SUCCESS,
                            roles
                        });
                    },
                    (error) => {
                        dispatch({
                            type: roleConstants.GET_LIST_FAILURE,
                            error: '讀取角色選單失敗：' + error
                        });
                    }
                )
        }
    },
    /**
     * Reset Roles List.
     */
    resetList() {
        return {
            type: roleConstants.RESET_LIST_SUCCESS,
        };
    },
    /**
     * Create Role.
     * @param {object} info - The info of role and permissions.
     * @param {requestCallback} cbSuccess - The callback of success.
     */
    create(info, cbSuccess) {
        return dispatch => {
            dispatch(maskActions.toggleMask(true));
            roleService.create(info)
                .then(
                    (response) => {
                        showModal(msgTypes.SUCCESS, '通知', response, cbSuccess);
                    },
                    (error) => {
                        showModal(msgTypes.ERROR, '錯誤', error);
                    }
                ).then(
                    () => {
                        dispatch(maskActions.toggleMask(false));
                    }
                );
        }
    },
    /**
     * Update Role.
     * @param {string} id - The id of role.
     * @param {object} info - The info of role and permissions.
     * @param {requestCallback} cbSuccess - The callback of success.
     */
    update(id, info, cbSuccess) {
        return dispatch => {
            dispatch(maskActions.toggleMask(true));
            roleService.update(id, info)
                .then(
                    (response) => {
                        showModal(msgTypes.SUCCESS, '通知', response, cbSuccess);
                    },
                    (error) => {
                        showModal(msgTypes.ERROR, '錯誤', error);
                    }
                ).then(
                    () => {
                        dispatch(maskActions.toggleMask(false));
                    }
                );
        }
    },
    /**
     * Delete Role.
     * @param {string} id - The id of role.
     */
    delete(id) {
        return (dispatch, getState) => {
            dispatch(maskActions.toggleMask(true));
            roleService.delete(id)
                .then(
                    (response) => {
                        showModal(msgTypes.SUCCESS, '通知', response, () => {
                            const rolePermissions = getState().rolePermissions;
                            dispatch(roleActions.getAll(rolePermissions && rolePermissions.queryCondition ? rolePermissions.queryCondition : null));
                        });
                    },
                    (error) => {
                        showModal(msgTypes.ERROR, '錯誤', error);
                    }
                ).then(
                    () => {
                        dispatch(maskActions.toggleMask(false));
                    }
                );
        }
    },
    /**
     * Get Edited Role Info.
     * @param {string} id - The id of role.
     * @param {requestCallback} cbError - The callback of error.
     * @description For role edit.
     */
    getEdited(id, cbError) {
        return dispatch => {
            dispatch(maskActions.toggleMask(true));
            roleService.get(id)
                .then(
                    (role) => {
                        let tranRole = reduce(role, function (memo, value, key) {
                            key !== 'permissions' && (memo[key] = { 'value': value });
                            return memo;
                        }, {});
                        const { permissions } = role;
                        tranRole.editPermissions = permissions && permissions.length ? permissions : [];
                        dispatch({
                            type: roleConstants.GET_EDIT_SUCCESS,
                            tranRole
                        });
                    },
                    (error) => {
                        showModal(msgTypes.ERROR, '錯誤', error, cbError);
                        dispatch({
                            type: roleConstants.GET_EDIT_FAILURE
                        });
                    }
                ).then(
                    () => {
                        dispatch(maskActions.toggleMask(false));
                    }
                );
        }
    },
    /**
     * Refresh Edited Role Info
     */
    refreshEdited(fields) {
        return {
            type: roleConstants.REFRESH_EDIT_SUCCESS,
            refreshEditInfo: fields
        };
    },
    /**
     * Refresh Edited Permissions Info
     */
    refreshEditedPermissions(permissions) {
        return {
            type: roleConstants.REFRESH_EDIT_PERMISSION_SUCCESS,
            refreshEditInfo: permissions
        };
    },
    /**
     * Reset Role Info
     */
    reset() {
        return {
            type: roleConstants.RESET_SUCCESS,
        };
    },
};