import { vendorService } from "../services";
import { vendorConstants } from "../constants";
import { maskActions } from ".";
import { reduce } from "lodash";
import { msgTypes, showModal } from "../utils";
import { message } from "antd";

export const vendorActions = {
  /**
   * Get Vendors Info.
   * @description For search grid's result.
   */
  getAll() {
    return dispatch => {
      dispatch({
        type: vendorConstants.GET_ALL_REQUEST
      });
      vendorService.getAll().then(
        response => {
          dispatch({
            type: vendorConstants.GET_ALL_SUCCESS,
            queryCondition: {},
            ...response
          });
        },
        error => {
          dispatch({
            type: vendorConstants.GET_ALL_FAILURE,
            error: "讀取廠商查詢結果失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Vendors Info
   */
  resetAll() {
    return {
      type: vendorConstants.RESET_ALL_SUCCESS
    };
  },
  /**
   * Get Vendors List.
   * @description For dropdownlist.
   */
  getList() {
    return dispatch => {
      dispatch({
        type: vendorConstants.GET_LIST_REQUEST
      });
      vendorService.getList().then(
        vendors => {
          dispatch({
            type: vendorConstants.GET_LIST_SUCCESS,
            vendors
          });
        },
        error => {
          dispatch({
            type: vendorConstants.GET_LIST_FAILURE,
            error: "讀取廠商選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Vendors List Info
   */
  resetList() {
    return {
      type: vendorConstants.RESET_LIST_SUCCESS
    };
  },
  /**
   * Get Edited Vendor Info.
   * @param {string} id - The id of vendor.
   * @param {requestCallback} cbError - The callback of error.
   * @description For vendor edit.
   */
  getEdited(id, cbError) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      dispatch({ type: vendorConstants.GET_EDITED_REQUEST });
      vendorService
        .get(id)
        .then(
          vendor => {
            let transVendor = reduce(
              vendor,
              function (memo, value, key) {
                memo[key] = { value: value };
                return memo;
              },
              {}
            );

            const { members = [] } = vendor;
            transVendor.members = members.map(member => ({
              oriId: member.id,
              id: { value: member.id },
              name: { value: member.name },
              phone: { value: member.phone },
              is_allow_delete: !member.is_setted
            }));

            dispatch({
              type: vendorConstants.GET_EDITED_SUCCESS,
              vendor: transVendor,
              oriVendor: vendor
            });
          },
          error => {
            dispatch({
              type: vendorConstants.GET_EDITED_FAILURE,
              error
            });
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Remove Member Data Row.
   * @param {string} memberId - The id of member.
   */
  removeMember(memberId) {
    return {
      type: vendorConstants.REMOVE_MEMBER,
      memberId
    };
  },
  /**
   * Add Member Data Row.
   */
  addMember() {
    return {
      type: vendorConstants.ADD_MEMBER
    };
  },
  /**
   * Create Vendor.
   * @param {object} info - The info of vendor.
   * @param {function} cbSuccess - The callback of success.
   */
  create(info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: vendorConstants.SET_EDITED_REQUEST
      });
      vendorService.create(info).then(
        response => {
          dispatch({
            type: vendorConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          vendorActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          dispatch({
            type: vendorConstants.SET_EDITED_FAILURE,
            error: `新增廠商資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Update Vendor.
   * @param {string} id - The id of vendor.
   * @param {object} info - The info of vendor.
   * @param {function} cbSuccess - The callback of success.
   */
  update(id, info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: vendorConstants.SET_EDITED_REQUEST
      });
      vendorService.update(id, info).then(
        response => {
          dispatch({
            type: vendorConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          vendorActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          dispatch({
            type: vendorConstants.SET_EDITED_FAILURE,
            error: `修改廠商資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Delete Vendor.
   * @param {string} id - The id of vendor.
   */
  delete(id) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      vendorService
        .delete(id)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              vendorActions.reloadGetAll(dispatch, getState);
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Refresh Edited Vendor Info.
   * @param {object} fields - The info of vendor.
   * @description Support Vendor edit.
   */
  refreshEdited(fields) {
    return {
      type: vendorConstants.REFRESH_EDITED_SUCCESS,
      refreshEditInfo: fields
    };
  },
  /**
   * Reset Vendor Info.
   */
  reset() {
    return {
      type: vendorConstants.RESET_EDITED_SUCCESS
    };
  },
  reloadGetAll(dispatch, getState) {
    dispatch(vendorActions.getAll());
  }
};
