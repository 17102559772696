import { ajax } from "../utils/ajax";
import { vendors } from "../.fakeDatas/vendor";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

export const vendorService = {
  /**
   * Send Get All Ajax Request.
   */
  getAll() {
    // return timeoutPromise(vendors.vendorsGetAll, 1000);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/vendors`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get List Ajax Request.
   */
  getList() {
    // return timeoutPromise(vendors.vendorsGetList, 1000);
    // return timeoutPromise("載入廠商失敗", 1000, true);
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/vendors/list`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get Vendor Info Ajax Request.
   * @param {string} id - The id of vendor.
   */
  get(id) {
    // return timeoutPromise(vendors.vendorEdit, 1000);
    // return timeoutPromise("讀取失敗", 1000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/vendors/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Create Vendor Ajax Request.
   * @param {object} info - The info of vendor.
   */
  create(info) {
    // return timeoutPromise("新增成功", 1000);

    return ajax
      .post(`${process.env.REACT_APP_API_URL}/vendors`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Update Vendor Ajax Request.
   * @param {string} id - The id of vendor.
   * @param {object} info - The info of vendor.
   */
  update(id, info) {
    // return timeoutPromise("修改成功", 1000);

    return ajax
      .put(`${process.env.REACT_APP_API_URL}/vendors/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Delete Vendor Ajax Request.
   * @param {string} id - The id of vendor .
   */
  delete(id) {
    // return timeoutPromise("刪除成功", 1000);

    return ajax
      .delete(`${process.env.REACT_APP_API_URL}/vendors/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  }
};
