import React, { useEffect } from "react";
import { connect } from "react-redux";
import routes from "./routes";
import { authActions } from "./actions";
import "./App.less";
import { Spin as AntSpin, ConfigProvider, Empty } from "antd";
import zhTW from "antd/es/locale/zh_TW";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { history, router } from "./utils";
library.add(fas);
library.add(fab);

/**
 * 網頁 Title 顯示目前模組名稱
 * @param {string} locationPathName 頁面路徑
 */
const updateDocumentTitle = locationPathName => {
  const routeKey = router.getKey(locationPathName);
  const routeName = router.getName(routeKey);
  document.title = `${routeName ? routeName + " | " : ""}永達營造後台管理系統`;
};

/**
 * 載入 loading
 *
 * 暫時先做出獨立 loading 元件，避免 mask 狀態會影響下面的子元件 rerender，若遇到重複 rerender 情況，可考慮採用此元件
 */
// const Spin = connect(state => ({
//   spinning: state.mask.spinning
// }))(({ spinning }) => (
//   <div
//     style={{
//       height: "100%",
//       width: "100%",
//       position: "absolute",
//       display: spinning ? "flex" : "none",
//       alignItems: "center",
//       justifyContent: "center",
//       zIndex: 1000,
//       background: "rgba(255, 255, 255, 0.5)"
//     }}
//   >
//     <AntSpin size="large" tip="讀取中，請稍後 ..." spinning={spinning}></AntSpin>
//   </div>
// ));

const App = props => {
  useEffect(() => {
    props.checkUserCredential();

    // 第一次進入頁面，更新頁面 title
    updateDocumentTitle(history.location.pathname);
    // 監聽頁面變更，以更新頁面 title
    history.listen(({ pathname }) => {
      updateDocumentTitle(pathname);
    });
  }, []);

  const customizeRenderEmpty = () => {
    return <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={"尚無資訊"} />;
  };

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty} locale={zhTW}>
      <AntSpin size="large" tip="讀取中，請稍後 ..." spinning={props.spinning}>
        <div style={{ height: "100vh" }}>{routes}</div>
      </AntSpin>
    </ConfigProvider>
  );
};

export default connect(
  state => ({
    spinning: state.mask.spinning
  }),
  {
    checkUserCredential: authActions.check
  }
)(App);
