import { ajax } from '../utils/ajax'

export const moduleCategoryService = {
    /**
     * Send Get Module Category Info Ajax Request.
     * @description For dropdownlist.
     */
    getList: () => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/permission-category/list`)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
};