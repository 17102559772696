import { driverRegistrationConstants } from "../constants";

const initState = {
  edit: {},
  save: {}
};

export const driverRegistration = (state = initState, action) => {
  switch (action.type) {
    case driverRegistrationConstants.GET_EDITED_REQUEST:
      return {
        ...state,
        edit: {
          loading: true
        }
      };
    case driverRegistrationConstants.GET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: action.driverRegistration,
          error: null
        }
      };
    case driverRegistrationConstants.GET_EDITED_FAILURE:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: null,
          error: action.error
        }
      };
    case driverRegistrationConstants.RESET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {},
        save: {}
      };
    case driverRegistrationConstants.REFRESH_EDITED_SUCCESS:
      // 初始 refresh
      if (!state.edit.editInfo) {
        return {
          ...state,
          edit: {
            editInfo: {
              ...action.refreshEditInfo
            }
          }
        };
      }

      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            ...action.refreshEditInfo
          }
        }
      };
    case driverRegistrationConstants.SET_EDITED_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      };
    case driverRegistrationConstants.SET_EDITED_SUCCESS:
      return {
        ...state,
        save: {
          processing: false
        }
      };
    case driverRegistrationConstants.SET_EDITED_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
