import { notification } from 'antd';

/**
 * Message type enums.
 */
export const noticeTypes = Object.freeze({
    INFO: Symbol("info"),
    SUCCESS: Symbol("success"),
    ERROR: Symbol("error"),
    WARNING: Symbol("warning")
});

/**
 * Show Notification.
 * @param {noticeTypes} type - The type of notice.
 * @param {string} title - The title of modal.
 * @param {string} content - The content of modal.
 */
export const showNotification = (type, title, content) => {
    let typeStr;
    switch (type) {
        case noticeTypes.SUCCESS:
        case noticeTypes.ERROR:
        case noticeTypes.WARNING:
            typeStr = type.description
            break;
        default:
            typeStr = noticeTypes.INFO.description;
            break;
    }
    notification[typeStr]({
        duration: 3,
        message: title,
        description: content
    });
}