import { moduleConstants } from '../constants';

export const modules = (state = {}, action) => {
    switch (action.type) {
        case moduleConstants.GET_ALL_REQUEST:
            return {
                ...state,
                queryLoading: true
            };
        case moduleConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                queryLoading: false,
                queryCondition: action.queryCondition,
                queryResult: action.modules
            };
        case moduleConstants.GET_ALL_FAILURE:
            return {
                ...state,
                queryLoading: false,
                queryFail: action.error,
            };
        case moduleConstants.RESET_ALL_SUCCESS:
            return {
                ...state,
                queryLoading: false,
                queryCondition: {},
                queryResult: [],
                queryFail: null,
            };
        case moduleConstants.GET_LIST_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case moduleConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                listItems: action.modules
            };
        case moduleConstants.GET_LIST_FAILURE:
            return {
                ...state,
                listLoading: false,
                getListFail: action.error
            };
        case moduleConstants.RESET_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                listItems: [],
                getListFail: null,
            };
        default:
            return state;
    }
}