import { secureStroge, storgeKeys } from "./";
import { find } from "lodash";

export const auth = {
  /**
   * 驗證角色
   * @param {string} key 角色索引值
   */
  CheckRole(key) {
    const userCredential = secureStroge.get(storgeKeys.userCredential);
    return find(userCredential.roles, ["key", key]) ? true : false;
  },
  /**
   * 驗證功能使用權限
   * @param {string} key 權限索引值
   */
  CheckPermission(key) {
    const userCredential = secureStroge.get(storgeKeys.userCredential);
    return find(userCredential.permissions, ["key", key]) ? true : false;
  }
};
