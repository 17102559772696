import { ajax } from "../utils/ajax";
import { engineeringClassifications } from "../.fakeDatas/engineeringClassification";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

export const engineeringClassificationService = {
  /**
   * Send Get List Ajax Request.
   */
  getList() {
    // return timeoutPromise(engineeringClassifications.engineeringClassificationsGetList, 3000);
    // return timeoutPromise("載入失敗工程別", 3000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/engineering-classifications/list`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  }
};
