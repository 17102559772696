import { history, secureStroge, storgeKeys, authHeader } from "../utils";
import { pickBy } from "lodash";

export const ajax = {
  /**
   * Ajax Request Response Handler.
   * @param {object} headers - The request headers object.
   * @param {bool} isWithToken - Is auto add authorization info into request headers.
   */
  _mergeHeaders(headers, isWithToken) {
    let finalHeader = {};
    if (isWithToken) {
      const authHeaders = authHeader();
      finalHeader = {
        ...authHeaders
      };
    }
    if (headers) {
      finalHeader = {
        ...finalHeader,
        ...headers
      };
    }
    return finalHeader;
  },
  /**
   * Ajax Request Response Handler.
   * @param {object} response - The request response object.
   */
  _handleResponse(response) {
    if (!response.ok) {
      const { status, statusText } = response;
      if (status === 500) {
        return Promise.reject({
          ...response,
          errorMessage: "發生非預期錯誤，請聯絡系統管理者！"
        });
      } else {
        return response.json().then(responseData => {
          let errorMessage;
          switch (status) {
            case 401:
              errorMessage = "登入逾期或失效，請重新進行登入";
              break;
            case 403:
              errorMessage = responseData.message;
              break;
            case 412:
              errorMessage = responseData.message;
              break;
            case 422:
              errorMessage = "參數資料或格式錯誤，請重新確認！";
              break;
            default:
              errorMessage = "發生非預期錯誤，請聯絡系統管理者！";
              break;
          }
          return Promise.reject({ status, statusText, errorMessage });
        });
      }
    }
    return response;
  },
  /**
   * Ajax Request Error Handler.
   * @param {object} error - The request error info object.
   */
  _handleError(error) {
    const { status, errorMessage } = error;
    switch (status) {
      case 401:
        secureStroge.remove(storgeKeys.userCredential);
        history.push("/login");
        return Promise.reject(errorMessage);
      case 403:
      case 412:
      case 422:
        return Promise.reject(errorMessage);
      default:
        return Promise.reject("發生請求錯誤，請聯絡系統管理者！");
    }
  },

  /**
   * Merge Query Params Into Url
   * @param {string} url - The request url.
   * @param {object} params - The query params.
   */
  _mergeParams(url, params) {
    const paramKeys = Object.keys(
      pickBy(params, prop => {
        return prop != null && typeof prop !== "undefined";
      })
    );
    if (paramKeys.length) {
      return `${url}${url.indexOf("?") === -1 ? "?" : "&"}${paramKeys
        .map(k => {
          if (Array.isArray(params[k])) {
            return params[k]
              .map(
                value =>
                  `${encodeURIComponent(k)}[]=${encodeURIComponent(value)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
        })
        .join("&")}`;
    }
    return url;
  },
  /**
   * Send Get's Ajax Request.
   * @param {string} url - The request url.
   * @param {object} optionsConfig - The optional request config. ex: { params, headers, timeout ... }
   */
  get(url, optionsConfig = {}) {
    return this._sendRequest("GET", url, optionsConfig);
  },
  /**
   * Send Post's Ajax Request.
   * @param {string} url - The request url.
   * @param {object} optionsConfig - The optional request config. ex: { params, headers, timeout ... }
   */
  post(url, optionsConfig = {}) {
    return this._sendRequest("POST", url, optionsConfig);
  },
  /**
   * Send Put's Ajax Request.
   * @param {string} url - The request url.
   * @param {object} optionsConfig - The optional request config. ex: { params, headers, timeout ... }
   */
  put(url, optionsConfig = {}) {
    return this._sendRequest("PUT", url, optionsConfig);
  },
  /**
   * Send Patch's Ajax Request.
   * @param {string} url - The request url.
   * @param {object} optionsConfig - The optional request config. ex: { params, headers, timeout ... }
   */
  patch(url, optionsConfig = {}) {
    return this._sendRequest("PATCH", url, optionsConfig);
  },
  /**
   * Send Delete's Ajax Request.
   * @param {string} url - The request url.
   * @param {object} optionsConfig - The optional request config. ex: { params, headers, timeout ... }
   */
  delete(url, optionsConfig = {}) {
    return this._sendRequest("DELETE", url, optionsConfig);
  },
  /**
   * Send Ajax Request.
   * @param {string} url - The request type.
   * @param {string} url - The request url.
   * @param {object} optionsConfig - The optional request config. ex: { params, headers, timeout ... }
   */
  _sendRequest(type, url, optionsConfig = {}) {
    const { isWithToken = true, headers, params, ...args } = optionsConfig;

    if (params) {
      url = this._mergeParams(url, params);
    }

    return fetch(url, {
      method: type,
      headers: this._mergeHeaders(headers, isWithToken),
      ...args
    })
      .then(this._handleResponse)
      .catch(this._handleError);
  },
  /**
   * Convert Params To FormData
   * @param {object} params - The params of form.
   */
  convertToFormData(params) {
    const formData = new FormData();
    const paramKeys = Object.keys(
      pickBy(params, prop => {
        return prop != null && typeof prop !== "undefined";
      })
    );
    paramKeys.forEach(key => {
      let param = params[key];
      if (Array.isArray(param)) {
        param.forEach(item => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(`${key}`, param);
      }
    });
    return formData;
  }
};
