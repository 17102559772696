import { authConstants } from "../constants";
import { secureStroge, storgeKeys } from "../utils/storage.util";

export const auth = (state = {}, action) => {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS:
    case authConstants.THIRDPARTY_LOGIN_SUCCESS:
    case authConstants.THIRDPARTY_REGISTER_SUCCESS:
    case authConstants.REFRESH_CREDENTIAL:
      secureStroge.set(storgeKeys.userCredential, {
        uuid: action.userInfo.uuid,
        token: action.userInfo.token,
        roles: action.userInfo.roles,
        permissions: action.userInfo.permissions
      });
      return Object.assign({}, state, {
        ...state,
        userInfo: action.userInfo,
        userPermissions: action.userInfo.permissions,
        userEShopStores: action.userInfo.eshop_stores
      });
    case authConstants.INIT_THIRDPARTY_REGISTER_SUCCESS:
      return {
        thirdpartyRegisterInfo: action.thirdpartyRegisterInfo
      };
    case authConstants.THIRDPARTY_REGISTER_FAILURE:
      return {
        ...state
      };
    case authConstants.RESET_REGISTER_SUCCESS:
      return {
        ...state,
        thirdpartyRegisterInfo: null
      };
    case authConstants.LOGIN_FAILURE:
    case authConstants.THIRDPARTY_LOGIN_FAILURE:
    case authConstants.LOGOUT:
      secureStroge.remove(storgeKeys.userCredential);
      return {};
    default:
      return state;
  }
};
