import { roleConstants } from '../constants';

export const roles = (state = {}, action) => {
    switch (action.type) {
        case roleConstants.GET_ALL_REQUEST:
            return {
                queryLoading: true
            };
        case roleConstants.GET_ALL_SUCCESS:
            return {
                queryCondition: action.queryCondition,
                queryResult: action.roles
            };
        case roleConstants.GET_ALL_FAILURE:
            return {
                queryFail: action.error,
            };
        case roleConstants.RESET_ALL_SUCCESS:
            return {};
        case roleConstants.GET_LIST_REQUEST:
            return {
                listLoading: true
            };
        case roleConstants.GET_LIST_SUCCESS:
            return {
                listItems: action.roles
            };
        case roleConstants.GET_LIST_FAILURE:
            return {
                getListFail: action.error
            };
        case roleConstants.RESET_LIST_SUCCESS:
            return {};
        default:
            return state
    }
}