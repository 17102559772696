import React, { useEffect } from "react";
import { connect } from "react-redux";
import { maskActions } from "../../actions";

const Loading = props => {
  useEffect(() => {
    // Mount
    props.toggleMask(true);
    // Unmount
    return () => {
      props.toggleMask(false);
    };
  }, []);

  return <div>Loading......</div>;
};

export default connect(
  undefined,
  { toggleMask: maskActions.toggleMask }
)(Loading);
