import { ajax } from "../utils/ajax";
import { driverRegistrations } from "../.fakeDatas/driverRegistration";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

export const driverRegistrationService = {
  /**
   * Send Get All Driver Registration Ajax Request.
   */
  getAll(condition) {
    // return timeoutPromise(driverRegistrations.driverRegistrationsGetAll, 1000);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/driver-registrations`, {
        params: {
          ...condition
        }
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get Driver Registration List Ajax Request.
   */
  getList() {
    // return timeoutPromise(driverRegistrations.driverRegistrationsGetList, 1000);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/driver-registrations/list`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get Driver Registration Info Ajax Request.
   * @param {string} id - The id of driver registration.
   */
  get(id) {
    // return timeoutPromise(driverRegistrations.driverRegistrationEdit(id), 1000);
    // return timeoutPromise("讀取駕駛車籍失敗", 1000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/driver-registrations/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Create Driver Registration Ajax Request.
   * @param {object} info - The info of driver registration type.
   */
  create(info) {
    // return timeoutPromise("新增成功", 1000);

    return ajax
      .post(`${process.env.REACT_APP_API_URL}/driver-registrations`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Update Driver Registration Ajax Request.
   * @param {string} id - The id of driver registration.
   * @param {object} info - The info of driver registration.
   */
  update(id, info) {
    // return timeoutPromise("修改成功", 1000);

    return ajax
      .put(`${process.env.REACT_APP_API_URL}/driver-registrations/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Delete Driver Registration Ajax Request.
   * @param {string} id - The id of driver registration.
   */
  delete(id) {
    // return timeoutPromise("刪除成功", 1000);

    return ajax
      .delete(`${process.env.REACT_APP_API_URL}/driver-registrations/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  }
};
