import { moduleCategoryConstants } from '../constants';

export const moduleCategories = (state = {}, action) => {
    switch (action.type) {
        case moduleCategoryConstants.GET_LIST_REQUEST:
            return {
                listLoading: true
            };
        case moduleCategoryConstants.GET_LIST_SUCCESS:
            return {
                listItems: action.moduleCategories
            };
        case moduleCategoryConstants.GET_LIST_FAILURE:
            return {
                getListFail: action.error
            };
        case moduleCategoryConstants.RESET_LIST_SUCCESS:
            return {};
        default:
            return state
    }
}