const soilChargeEngineeringProjectsGetAll = [
  {
    id: 1,
    engineering_classification_id: 1,
    engineering_classification: "相關拆除工程",
    control_number: "BBC6993",
    name: "Tokelau Grill Geekola",
    location: "Oman Juarez Kings Place",
    contract_vendor_id: 5,
    contract_vendor: "Victoria",
    contract_vendor_principal: "Zamora",
    contract_vendor_principal_phone: 9884463130,
    supervision_vendor_id: 4,
    supervision_vendor: "Gwen",
    supervision_vendor_principal: "Boyer",
    supervision_vendor_principal_phone: 9635473803,
    start_date: "2014-07-14 10:39:36",
    end_date: "2014-04-23 10:10:52",
    approved_cb_count: 8250904,
    current_soil_cb_count: 0,
    current_soil_ton_count: 0,
    drive_count: 0,
    soil_supply_engineerings: [
      {
        id: 1,
        name: "XXX 出土工程1"
      },
      {
        id: 2,
        name: "XXX 出土工程2"
      }
    ]
  },
  {
    id: 2,
    engineering_classification_id: 1,
    engineering_classification: "建築工程",
    control_number: "BAA7756",
    name: "Venezuela Idamay Verbus",
    location: "Cook Islands Klondike Crosby Avenue",
    contract_vendor_id: 3,
    contract_vendor: "Regina",
    contract_vendor_principal: "Clarke",
    contract_vendor_principal_phone: 8444082123,
    supervision_vendor_id: 3,
    supervision_vendor: "Anderson",
    supervision_vendor_principal: "Chris",
    supervision_vendor_principal_phone: 8914893961,
    start_date: "2014-02-08 04:48:01",
    end_date: "2014-02-23 07:12:03",
    approved_cb_count: 4265344,
    current_soil_cb_count: 216936,
    current_soil_ton_count: 819278,
    drive_count: 30,
    soil_supply_engineerings: [
      {
        id: 1,
        name: "XXX 出土工程1"
      },
      {
        id: 2,
        name: "XXX 出土工程2"
      }
    ]
  },
  {
    id: 3,
    engineering_classification_id: 1,
    engineering_classification: "相關拆除工程",
    control_number: "CCC8320",
    name: "Israel Springhill Comtract",
    location: "Mali Calpine Vandam Street",
    contract_vendor_id: 4,
    contract_vendor: "Rowe",
    contract_vendor_principal: "Simmons",
    contract_vendor_principal_phone: 9355572615,
    supervision_vendor_id: 5,
    supervision_vendor: "Mills",
    supervision_vendor_principal: "Sheryl",
    supervision_vendor_principal_phone: 8795613274,
    start_date: "2014-10-17 08:00:21",
    end_date: "2014-10-10 03:33:50",
    approved_cb_count: 1425207,
    current_soil_cb_count: 175724,
    current_soil_ton_count: 846615,
    drive_count: 542,
    soil_supply_engineerings: [
      {
        id: 2,
        name: "XXX 出土工程5"
      }
    ]
  },
  {
    id: 4,
    engineering_classification_id: 1,
    engineering_classification: "建築工程",
    control_number: "BCB6938",
    name: "Cape Verde Wescosville Renovize",
    location: "Rwanda Orick Willow Place",
    contract_vendor_id: 1,
    contract_vendor: "Bowman",
    contract_vendor_principal: "Berta",
    contract_vendor_principal_phone: 8345812296,
    supervision_vendor_id: 1,
    supervision_vendor: "Hays",
    supervision_vendor_principal: "Holden",
    supervision_vendor_principal_phone: 8765653090,
    start_date: "2014-04-16 06:29:20",
    end_date: "2014-05-09 08:16:10",
    approved_cb_count: 4709029,
    current_soil_cb_count: 524608,
    current_soil_ton_count: 696242,
    drive_count: 71,
    soil_supply_engineerings: [
      {
        id: 1,
        name: "XXX 出土工程4"
      }
    ]
  },
  {
    id: 5,
    engineering_classification_id: 3,
    engineering_classification: "建築工程",
    control_number: "CCB2391",
    name: "Vanuatu Olney Insurety",
    location: "Laos Maxville Rockaway Avenue",
    contract_vendor_id: 2,
    contract_vendor: "Kristen",
    contract_vendor_principal: "Clarice",
    contract_vendor_principal_phone: 8414152104,
    supervision_vendor_id: 5,
    supervision_vendor: "Silvia",
    supervision_vendor_principal: "Malone",
    supervision_vendor_principal_phone: 9875833376,
    start_date: "2014-08-20 04:22:10",
    end_date: "2014-03-13 10:00:19",
    approved_cb_count: 9517790,
    current_soil_cb_count: 785885,
    current_soil_ton_count: 949308,
    soil_supply_engineerings: []
  }
];

export const soilChargeEngineeringProjects = {
  soilChargeEngineeringProjectsGetAll: {
    total: soilChargeEngineeringProjectsGetAll.length,
    items: soilChargeEngineeringProjectsGetAll
  },
  soilChargeEngineeringProjectsEdit(id) {
    return soilChargeEngineeringProjectsGetAll.find(item => item.id === id);
  },
  soilChargeEngineeringProjectsGetList: soilChargeEngineeringProjectsGetAll
  // soilChargeEngineeringProjectsGetList: [soilChargeEngineeringProjectsGetAll[0]]
};
