import { userConstants } from "../constants";

export const user = (state = {}, action) => {
  switch (action.type) {
    case userConstants.GET_EDIT_SUCCESS:
      return {
        editInfo: action.user
      };
    case userConstants.REFRESH_EDIT_SUCCESS:
      return {
        editInfo: {
          ...state.editInfo,
          ...action.refreshEditInfo
        }
      };
    case userConstants.SET_TELEPHONE_REQUEST:
      return {
        ...state,
        telephoneProcessing: true
      };
    case userConstants.SET_TELEPHONE_FAILURE:
      return {
        ...state,
        telephoneProcessing: false,
        telephoneProcessFail: action.error
      };
    case userConstants.SET_TELEPHONE_SUCCESS:
    case userConstants.RESET_TELEPHONE_SETTING:
      return {
        ...state,
        telephoneProcessing: false,
        telephoneProcessFail: null
      };
    case userConstants.SET_PWD_REQUEST:
      return {
        ...state,
        passwordProcessing: true
      };
    case userConstants.SET_PWD_FAILURE:
      return {
        ...state,
        passwordProcessing: false,
        passwordProcessFail: action.error
      };
    case userConstants.SET_PWD_SUCCESS:
    case userConstants.RESET_PWD_SETTING_SUCCESS:
      return {
        ...state,
        passwordProcessing: false,
        passwordProcessFail: null
      };
    case userConstants.SET_AVATAR_REQUEST:
      return {
        ...state,
        avatarProcessing: true
      };
    case userConstants.SET_AVATAR_FAILURE:
      return {
        ...state,
        avatarProcessing: false,
        avatarProcessFail: action.error
      };
    case userConstants.SET_AVATAR_SUCCESS:
    case userConstants.RESET_AVATAR_SUCCESS:
      return {
        ...state,
        avatarProcessing: false,
        avatarProcessFail: null
      };
    case userConstants.FORCE_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        forceChangePasswordProcessing: true
      };
    case userConstants.FORCE_CHANGE_PASSWORD_SUCCESS:
    case userConstants.RESET_FORCE_CHANGE_PASSWORD:
      return {
        ...state,
        forceChangePasswordProcessing: false,
        forceChangePasswordFail: false
      };
    case userConstants.FORCE_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        forceChangePasswordProcessing: false,
        forceChangePasswordFail: action.error
      };
    case userConstants.GET_EDIT_FAILURE:
    case userConstants.RESET_SUCCESS:
      return {};
    default:
      return state;
  }
};
