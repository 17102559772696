import NP from "number-precision";
import { isNil } from "lodash";

export const number = {
  toPercent(value, value2, precise = 2) {
    if (!isNil(value) && !isNil(value2)) {
      return NP.round(NP.times(NP.divide(value, value2), 100), precise);
    }

    return NP.times(value, 100);
  }
};
