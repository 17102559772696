import React from "react";
import { Select } from "antd";
import { find, reduce } from "lodash";
const { Option } = Select;

export const antd = {
  renderOptions: (listItems, key, value, selectedValues) => {
    let options = [];
    if (listItems && listItems.length) {
      options = listItems.map(item => (
        <Option key={item[key]}>{item[value]}</Option>
      ));
    }
    if (selectedValues) {
      listItems = listItems && listItems.length ? listItems : [];
      selectedValues = Array.isArray(selectedValues)
        ? selectedValues
        : [selectedValues];
      selectedValues.forEach(selectedValue => {
        if (
          !find(listItems, item => {
            return item[key] == selectedValue;
          })
        ) {
          options.unshift(
            <Option key={selectedValue}>
              <span style={{ color: "#BABABA" }}>{"原始設定值"}</span>
              <span style={{ color: "#EA7B73" }}>{"（不存在清單）"}</span>
            </Option>
          );
        }
      });
    }
    return options;
  },
  /**
   * 轉換資料物件至 Antd Form 格式（將 Form 的值放入 value 屬性中）
   * @param {object} data - 資料物件
   * @param {object} converter - 轉換規則物件
   */
  transferToFormFormat: (data, converter = {}) => {
    return reduce(
      data,
      function (memo, value, key) {
        const mapper = converter[key];
        memo[key] = {
          value: mapper
            ? typeof mapper === "function"
              ? mapper(value)
              : mapper
            : value
        };
        return memo;
      },
      {}
    );
  }
};
