import { roleConstants } from '../constants';

export const role = (state = {
    editInfo: {
        editPermissions: []
    }
}, action) => {
    switch (action.type) {
        case roleConstants.GET_EDIT_SUCCESS:
            return {
                editInfo: action.tranRole
            };
        case roleConstants.REFRESH_EDIT_SUCCESS:
            return {
                editInfo: {
                    ...state.editInfo,
                    ...action.refreshEditInfo,
                }
            };
        case roleConstants.REFRESH_EDIT_PERMISSION_SUCCESS:
            return {
                editInfo: {
                    ...state.editInfo,
                    editPermissions: [
                        ...action.refreshEditInfo
                    ]
                }
            };
        case roleConstants.RESET_SUCCESS:
            return {
                editInfo: {
                    editPermissions: []
                }
            };
        default:
            return state
    }
}