export const authConstants = {
  // 登入
  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",
  REFRESH_CREDENTIAL: "AUTH_REFRESH_CREDENTIAL_SUCCESS",
  // 第三方登入
  // THIRDPARTY_LOGIN_REQUEST: 'AUTH_THIRDPARTY_LOGIN_REQUEST',
  THIRDPARTY_LOGIN_SUCCESS: "AUTH_THIRDPARTY_LOGIN_SUCCESS",
  THIRDPARTY_LOGIN_FAILURE: "AUTH_THIRDPARTY_LOGIN_FAILURE",
  // 第三方註冊
  INIT_THIRDPARTY_REGISTER_SUCCESS: "AUTH_INIT_THIRDPARTY_REGISTER_SUCCESS",
  THIRDPARTY_REGISTER_SUCCESS: "AUTH_THIRDPARTY_REGISTER_SUCCESS",
  THIRDPARTY_REGISTER_FAILURE: "AUTH_THIRDPARTY_REGISTER_FAILURE",
  RESET_REGISTER_SUCCESS: "AUTH_RESET_REGISTER_SUCCESS",
  // 登出
  LOGOUT: "AUTH_LOGOUT"
};
