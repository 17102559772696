import { soilClassificationConstants } from "../constants";

const initState = {
  list: {}
};

export const soilClassifications = (state = initState, action) => {
  switch (action.type) {
    case soilClassificationConstants.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          loading: true,
          listItems: null,
          getListFail: null
        }
      };
    case soilClassificationConstants.GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          loading: false,
          listItems: action.soilClassficiations,
          getListFail: null
        }
      };
    case soilClassificationConstants.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          loading: false,
          listItems: null,
          getListFail: action.error
        }
      };
    case soilClassificationConstants.RESET_LIST_SUCCESS:
      return {
        ...state,
        list: {}
      };
    default:
      return state;
  }
};
