export const soilSupplyEngineeringConstants = {
  // 取得清單資料
  GET_ALL_REQUEST: "SOIL_SUPPLY_ENGINEERING_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "SOIL_SUPPLY_ENGINEERING_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "SOIL_SUPPLY_ENGINEERING_GET_ALL_FAILURE",
  RESET_ALL_SUCCESS: "SOIL_SUPPLY_ENGINEERING_RESETALL_SUCCESS",
  // 取得列表資料
  GET_LIST_REQUEST: "SOIL_SUPPLY_ENGINEERING_GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "SOIL_SUPPLY_ENGINEERING_GET_LIST_SUCCESS",
  GET_LIST_FAILURE: "SOIL_SUPPLY_ENGINEERING_GET_LIST_FAILURE",
  RESET_LIST_SUCCESS: "SOIL_SUPPLY_ENGINEERING_RESET_LIST_SUCCESS",
  // 取得編輯中資料
  GET_EDITED_REQUEST: "SOIL_SUPPLY_ENGINEERING_GET_EDITED_REQUEST",
  GET_EDITED_SUCCESS: "SOIL_SUPPLY_ENGINEERING_GET_EDITED_SUCCESS",
  GET_EDITED_FAILURE: "SOIL_SUPPLY_ENGINEERING_GET_EDITED_FAILURE",
  RESET_EDITED_SUCCESS: "SOIL_SUPPLY_ENGINEERING_RESET_EDITED_SUCCESS",
  REFRESH_EDITED_SUCCESS: "SOIL_SUPPLY_ENGINEERING_REFRESH_EDITED_SUCCESS",
  ADD_SOIL_CLASSIFICATION: "SOIL_SUPPLY_ENGINEERING_ADD_SOIL_CLASSIFICATION",
  REMOVE_SOIL_CLASSIFICATION: "SOIL_SUPPLY_ENGINEERING_REMOVE_SOIL_CLASSIFICATION",
  // 儲存編輯資料用
  SET_EDITED_REQUEST: "SOIL_SUPPLY_ENGINEERING_SET_EDITED_REQUEST",
  SET_EDITED_SUCCESS: "SOIL_SUPPLY_ENGINEERING_SET_EDITED_SUCCESS",
  SET_EDITED_FAILURE: "SOIL_SUPPLY_ENGINEERING_SET_EDITED_FAILURE"
};
