import { soilTransportRecordService } from "../services";
import { soilTransportRecordConstants } from "../constants";
import { maskActions } from ".";
import { reduce } from "lodash";
import { msgTypes, showModal } from "../utils";
import { message } from "antd";
import moment from "moment";

export const soilTransportRecordActions = {
  /**
   * Get Soil Transport Record Info.
   * @param {string} projectId - The id of soil charge engineering project.
   * @param {object} condition - The condition of query.
   * @description For search grid's result.
   */
  getAll(projectId, condition) {
    return dispatch => {
      dispatch({
        type: soilTransportRecordConstants.GET_ALL_REQUEST
      });
      const resultQueryCondition = { ...condition, soil_charge_engineering_project_id: projectId };
      soilTransportRecordService.getAll(resultQueryCondition).then(
        response => {
          dispatch({
            type: soilTransportRecordConstants.GET_ALL_SUCCESS,
            projectId,
            queryCondition: resultQueryCondition,
            ...response
          });
        },
        error => {
          dispatch({
            type: soilTransportRecordConstants.GET_ALL_FAILURE,
            error: "讀取運土紀錄查詢結果失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Soil Transport Record Info
   */
  resetAll() {
    return {
      type: soilTransportRecordConstants.RESET_ALL_SUCCESS
    };
  },
  /**
   * Get View Soil Transport Record Info.
   * @param {string} id - The id of soil transport record.
   * @description For soil transport record view.
   */
  getView(id, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({ type: soilTransportRecordConstants.GET_VIEW_REQUEST });
      const { query } = getState().soilTransportRecords;
      const condition = query && query.queryCondition ? query.queryCondition : null;

      soilTransportRecordService.get(id, condition).then(
        soilTransportRecord => {
          soilTransportRecord.transport_at = soilTransportRecord.transport_at
            ? moment(soilTransportRecord.transport_at).format("YYYY-MM-DD HH:mm")
            : null;

          cbSuccess && cbSuccess();

          dispatch({
            type: soilTransportRecordConstants.GET_VIEW_SUCCESS,
            soilTransportRecord
          });
        },
        error => {
          dispatch({
            type: soilTransportRecordConstants.GET_VIEW_FAILURE,
            error
          });
        }
      );
    };
  },
  /**
   * Reset View Soil Transport Record Info.
   */
  resetView() {
    return {
      type: soilTransportRecordConstants.RESET_VIEW_SUCCESS
    };
  },
  /**
   * Get Edited Soil Transport Record Info.
   * @param {string} id - The id of soil transport record.
   * @description For soil transport record edit.
   */
  getEdited(id) {
    return (dispatch, getState) => {
      // dispatch(maskActions.toggleMask(true));
      dispatch({ type: soilTransportRecordConstants.GET_EDITED_REQUEST });
      const { query } = getState().soilTransportRecords;
      const condition = query && query.queryCondition ? query.queryCondition : null;

      soilTransportRecordService
        .get(id, condition)
        .then(
          soilTransportRecord => {
            let transSoilTransportRecord = soilTransportRecord;

            transSoilTransportRecord = reduce(
              soilTransportRecord,
              function (memo, value, key) {
                memo[key] = { value: value };
                return memo;
              },
              {}
            );

            const { driver_registration_id, soil_supply_classification_id, transport_at } =
              soilTransportRecord;
            transSoilTransportRecord.driver_registration_id = {
              value: driver_registration_id ? driver_registration_id : undefined
            };
            transSoilTransportRecord.soil_supply_classification_id = {
              value: soil_supply_classification_id ? soil_supply_classification_id : undefined
            };
            transSoilTransportRecord.transport_at = {
              value: transport_at ? moment(transport_at) : null
            };

            dispatch({
              type: soilTransportRecordConstants.GET_EDITED_SUCCESS,
              soilTransportRecord: transSoilTransportRecord
            });
          },
          error => {
            dispatch({
              type: soilTransportRecordConstants.GET_EDITED_FAILURE,
              error
            });
          }
        )
        .then(() => {
          // dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Create Soil Transport Record.
   * @param {object} info - The info of soil transport record.
   * @param {function} cbSuccess - The callback of success.
   */
  create(info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: soilTransportRecordConstants.SET_EDITED_REQUEST
      });
      soilTransportRecordService.create(info).then(
        response => {
          dispatch({
            type: soilTransportRecordConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          soilTransportRecordActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          const errorMessage = `新增運土紀錄資料失敗：${error}`;
          message.error(errorMessage);
          dispatch({
            type: soilTransportRecordConstants.SET_EDITED_FAILURE,
            error: `新增運土紀錄資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Update Soil Transport Record.
   * @param {string} id - The id of soil transport record.
   * @param {object} info - The info of soil transport record.
   * @param {function} cbSuccess - The callback of success.
   */
  update(id, info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: soilTransportRecordConstants.SET_EDITED_REQUEST
      });
      soilTransportRecordService.update(id, info).then(
        response => {
          dispatch({
            type: soilTransportRecordConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          soilTransportRecordActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          const errorMessage = `修改運土紀錄資料失敗：${error}`;
          message.error(errorMessage);
          dispatch({
            type: soilTransportRecordConstants.SET_EDITED_FAILURE,
            error: errorMessage
          });
        }
      );
    };
  },
  /**
   * Delete Soil Transport Record.
   * @param {string} id - The id of soil transport record.
   * @param {function} cbSuccess - The callback of success.
   */
  delete(id, cbSuccess) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      soilTransportRecordService
        .delete(id)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response);

            soilTransportRecordActions.reloadGetAll(dispatch, getState);
            if (cbSuccess && typeof cbSuccess === "function") {
              cbSuccess();
            }
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Export Soil Transport Record Report.
   */
  export() {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      const { query } = getState().soilTransportRecords;
      soilTransportRecordService
        .export(
          query && query.projectId ? query.projectId : null,
          query && query.queryCondition ? query.queryCondition : null
        )
        .then(
          () => {
            dispatch(maskActions.toggleMask(false));
            message.success("匯出成功");
          },
          error => {
            dispatch(maskActions.toggleMask(false));
            message.error(`匯出失敗：${error}`);
          }
        );
    };
  },
  /**
   * Refresh Edited Soil Transport Record Info.
   * @param {object} fields - The info of soil transport record.
   * @description Support soil transport record edit.
   */
  refreshEdited(fields) {
    return {
      type: soilTransportRecordConstants.REFRESH_EDITED_SUCCESS,
      refreshEditInfo: fields
    };
  },
  /**
   * Reset Soil Transport Record Info.
   */
  reset() {
    return {
      type: soilTransportRecordConstants.RESET_EDITED_SUCCESS
    };
  },
  reloadGetAll(dispatch, getState) {
    const { query } = getState().soilTransportRecords;
    dispatch(
      soilTransportRecordActions.getAll(
        query && query.projectId ? query.projectId : null,
        query && query.queryCondition ? query.queryCondition : null
      )
    );
  }
};
