import { secureStroge, storgeKeys } from './'

export const authHeader = () => {
    let userCredential = secureStroge.get(storgeKeys.userCredential);
    if (userCredential && userCredential.token) {
        return {
            'Authorization': userCredential.token
        };
    } else {
        return {};
    }
}