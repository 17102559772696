import { userConstants } from "../constants";

export const users = (state = {}, action) => {
  switch (action.type) {
    case userConstants.GET_ALL_REQUEST:
      return {
        queryLoading: true,
      };
    case userConstants.GET_ALL_SUCCESS:
      return {
        queryCondition: action.queryCondition,
        total: action.total,
        queryResult: action.items,
      };
    case userConstants.GET_ALL_FAILURE:
      return {
        queryFail: action.error,
      };
    case userConstants.GET_LIST_REQUEST:
      return {
        ...state,
        listLoading: true,
        listItems: null,
        getListFail: null,
      };
    case userConstants.GET_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        listItems: action.users,
        getListFail: null,
      };
    case userConstants.GET_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        listItems: action.users,
        getListFail: action.error,
      };
    case userConstants.RESET_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        listItems: null,
        getListFail: null,
      };
    case userConstants.RESET_ALL_SUCCESS:
      return {};
    default:
      return state;
  }
};
