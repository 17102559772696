export const moduleConstants = {
    // GET ALL: 查詢選單用
    GET_ALL_REQUEST: 'MODULE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'MODULE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'MODULE_GET_ALL_FAILURE',
    RESET_ALL_SUCCESS: 'MODULE_RESETALL_SUCCESS',
    // GET LIST: 列表清單用
    GET_LIST_REQUEST: 'MODULE_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'MODULE_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'MODULE_GET_LIST_FAILURE',
    RESET_LIST_SUCCESS: 'MODULE_RESET_LIST_SUCCESS',
    // SET EDITED: 設定編輯資料用
    SET_EDITED_REQUEST: 'MODULE_SET_EDITED_REQUEST',
    SET_EDITED_SUCCESS: 'MODULE_SET_EDITED_SUCCESS',
    SET_EDITED_FAILURE: 'MODULE_SET_EDITED_FAILURE',
    // GET EDITED: 取得編輯資料用
    GET_EDIT_REQUEST: 'MODULE_GET_EDIT_REQUEST',
    GET_EDIT_SUCCESS: 'MODULE_GET_EDIT_SUCCESS',
    GET_EDIT_FAILURE: 'MODULE_GET_EDIT_FAILURE',
    REFRESH_EDIT_SUCCESS: 'MODULE_REFRESH_EDIT_SUCCESS',
    RESET_SUCCESS: 'MODULE_RESET_SUCCESS'
};