import { driverRegistrationService } from "../services";
import { driverRegistrationConstants } from "../constants";
import { maskActions } from ".";
import { reduce } from "lodash";
import { msgTypes, showModal } from "../utils";
import { message } from "antd";

export const driverRegistrationActions = {
  /**
   * Get Driver Registration Info.
   * @description For search grid's result.
   */
  getAll(condition) {
    return dispatch => {
      dispatch({
        type: driverRegistrationConstants.GET_ALL_REQUEST
      });
      driverRegistrationService.getAll(condition).then(
        response => {
          dispatch({
            type: driverRegistrationConstants.GET_ALL_SUCCESS,
            queryCondition: { ...condition },
            ...response
          });
        },
        error => {
          dispatch({
            type: driverRegistrationConstants.GET_ALL_FAILURE,
            error: "讀取駕駛車籍查詢結果失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Driver Registration Info
   */
  resetAll() {
    return {
      type: driverRegistrationConstants.RESET_ALL_SUCCESS
    };
  },
  /**
   * Get Driver Registration List.
   * @description For dropdownlist.
   */
  getList() {
    return dispatch => {
      dispatch({
        type: driverRegistrationConstants.GET_LIST_REQUEST
      });
      driverRegistrationService.getList().then(
        driverRegistrations => {
          dispatch({
            type: driverRegistrationConstants.GET_LIST_SUCCESS,
            driverRegistrations
          });
        },
        error => {
          dispatch({
            type: driverRegistrationConstants.GET_LIST_FAILURE,
            error: "讀取駕駛車籍選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Driver Registration List Info
   */
  resetList() {
    return {
      type: driverRegistrationConstants.RESET_LIST_SUCCESS
    };
  },
  /**
   * Get Edited Driver Registration Info.
   * @param {string} id - The id of driver registration.
   * @param {requestCallback} cbError - The callback of error.
   * @description For driver registration edit.
   */
  getEdited(id, cbError) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      dispatch({ type: driverRegistrationConstants.GET_EDITED_REQUEST });
      driverRegistrationService
        .get(id)
        .then(
          driverRegistration => {
            const { is_foreigner } = driverRegistration;
            let transdriverRegistration = reduce(
              driverRegistration,
              function (memo, value, key) {
                memo[key] = { value: value };
                return memo;
              },
              {}
            );

            transdriverRegistration.is_foreigner = {
              value: !!is_foreigner
            };

            dispatch({
              type: driverRegistrationConstants.GET_EDITED_SUCCESS,
              driverRegistration: transdriverRegistration
            });
          },
          error => {
            dispatch({
              type: driverRegistrationConstants.GET_EDITED_FAILURE,
              error
            });
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Create Driver Registration.
   * @param {object} info - The info of driver registration.
   * @param {function} cbSuccess - The callback of success.
   */
  create(info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: driverRegistrationConstants.SET_EDITED_REQUEST
      });
      driverRegistrationService.create(info).then(
        response => {
          dispatch({
            type: driverRegistrationConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          driverRegistrationActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          dispatch({
            type: driverRegistrationConstants.SET_EDITED_FAILURE,
            error: `新增駕駛車籍資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Update Driver Registration.
   * @param {string} id - The id of driver registration.
   * @param {object} info - The info of driver registration.
   * @param {function} cbSuccess - The callback of success.
   */
  update(id, info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: driverRegistrationConstants.SET_EDITED_REQUEST
      });
      driverRegistrationService.update(id, info).then(
        response => {
          dispatch({
            type: driverRegistrationConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          driverRegistrationActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          dispatch({
            type: driverRegistrationConstants.SET_EDITED_FAILURE,
            error: `修改駕駛車籍資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Delete Driver Registration.
   * @param {string} id - The id of driver registration.
   */
  delete(id) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      driverRegistrationService
        .delete(id)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              driverRegistrationActions.reloadGetAll(dispatch, getState);
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Refresh Edited Driver Registration Info.
   * @param {object} fields - The info of driver registration.
   * @description Support driver registration edit.
   */
  refreshEdited(fields) {
    return {
      type: driverRegistrationConstants.REFRESH_EDITED_SUCCESS,
      refreshEditInfo: fields
    };
  },
  /**
   * Reset Driver Registration Info.
   */
  reset() {
    return {
      type: driverRegistrationConstants.RESET_EDITED_SUCCESS
    };
  },
  reloadGetAll(dispatch, getState) {
    dispatch(driverRegistrationActions.getAll());
  }
};
