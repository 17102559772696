import { SMSVerificationConstants } from '../constants';

export const SMSVerification = (state = { seconds: 0 }, action) => {
    switch (action.type) {
        case SMSVerificationConstants.SEND_REQUEST:
            return {
                sending: true,
                seconds: 0
            };
        case SMSVerificationConstants.SEND_SUCCESS:
            return {
                sending: false,
                seconds: action.seconds
            };
        case SMSVerificationConstants.SEND_FAILURE:
        case SMSVerificationConstants.RESET_SUCCESS:
            return {
                sending: false,
                seconds: 0
            };
        default:
            return state
    }
}