export const vendorConstants = {
  // 取得清單資料
  GET_ALL_REQUEST: "VENDOR_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "VENDOR_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "VENDOR_GET_ALL_FAILURE",
  RESET_ALL_SUCCESS: "VENDOR_RESETALL_SUCCESS",
  // 取得列表資料
  GET_LIST_REQUEST: "VENDOR_GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "VENDOR_GET_LIST_SUCCESS",
  GET_LIST_FAILURE: "VENDOR_GET_LIST_FAILURE",
  RESET_LIST_SUCCESS: "VENDOR_RESET_LIST_SUCCESS",
  // 取得編輯中資料
  GET_EDITED_REQUEST: "VENDOR_GET_EDITED_REQUEST",
  GET_EDITED_SUCCESS: "VENDOR_GET_EDITED_SUCCESS",
  GET_EDITED_FAILURE: "VENDOR_GET_EDITED_FAILURE",
  RESET_EDITED_SUCCESS: "VENDOR_RESET_EDITED_SUCCESS",
  REFRESH_EDITED_SUCCESS: "VENDOR_REFRESH_EDITED_SUCCESS",
  REMOVE_MEMBER: "VENDOR_REMOVE_MEMBER",
  ADD_MEMBER: "VENDOR_ADD_MEMBER",
  // 儲存編輯資料用
  SET_EDITED_REQUEST: "VENDOR_SET_EDITED_REQUEST",
  SET_EDITED_SUCCESS: "VENDOR_SET_EDITED_SUCCESS",
  SET_EDITED_FAILURE: "VENDOR_SET_EDITED_FAILURE"
};
