export const driverRegistrationConstants = {
  // 取得清單資料
  GET_ALL_REQUEST: "DRIVER_REGISTRATION_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "DRIVER_REGISTRATION_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "DRIVER_REGISTRATION_GET_ALL_FAILURE",
  RESET_ALL_SUCCESS: "DRIVER_REGISTRATION_RESETALL_SUCCESS",
  // 取得列表資料
  GET_LIST_REQUEST: "DRIVER_REGISTRATION_GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "DRIVER_REGISTRATION_GET_LIST_SUCCESS",
  GET_LIST_FAILURE: "DRIVER_REGISTRATION_GET_LIST_FAILURE",
  RESET_LIST_SUCCESS: "DRIVER_REGISTRATION_RESET_LIST_SUCCESS",
  // 取得編輯中資料
  GET_EDITED_REQUEST: "DRIVER_REGISTRATION_GET_EDITED_REQUEST",
  GET_EDITED_SUCCESS: "DRIVER_REGISTRATION_GET_EDITED_SUCCESS",
  GET_EDITED_FAILURE: "DRIVER_REGISTRATION_GET_EDITED_FAILURE",
  RESET_EDITED_SUCCESS: "DRIVER_REGISTRATION_RESET_EDITED_SUCCESS",
  REFRESH_EDITED_SUCCESS: "DRIVER_REGISTRATION_REFRESH_EDITED_SUCCESS",
  // 儲存編輯資料用
  SET_EDITED_REQUEST: "DRIVER_REGISTRATION_SET_EDITED_REQUEST",
  SET_EDITED_SUCCESS: "DRIVER_REGISTRATION_SET_EDITED_SUCCESS",
  SET_EDITED_FAILURE: "DRIVER_REGISTRATION_SET_EDITED_FAILURE"
};
