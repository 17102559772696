import { engineeringClassificationService } from "../services";
import { engineeringClassificationConstants } from "../constants";

export const engineeringClassificationActions = {
  /**
   * Get Engineering Classification List.
   * @description For dropdownlist.
   */
  getList() {
    return dispatch => {
      dispatch({
        type: engineeringClassificationConstants.GET_LIST_REQUEST
      });
      engineeringClassificationService.getList().then(
        engineeringClassficiations => {
          dispatch({
            type: engineeringClassificationConstants.GET_LIST_SUCCESS,
            engineeringClassficiations
          });
        },
        error => {
          dispatch({
            type: engineeringClassificationConstants.GET_LIST_FAILURE,
            error: "讀取工程類別選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Engineering Classification List Info
   */
  resetList() {
    return {
      type: engineeringClassificationConstants.RESET_LIST_SUCCESS
    };
  }
};
