import { reportService } from "../services";
import { maskActions } from ".";
import { message } from "antd";

export const reportActions = {
  /**
   * Export Gov Soil Charge Summary.
   * @param {string} projectId - The id of soil charge engineering projects
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeSummary(projectId, condition) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      reportService.exportGovSoilChargeSummary({ id: projectId, ...condition }).then(
        () => {
          dispatch(maskActions.toggleMask(false));
          message.success("匯出成功");
        },
        error => {
          dispatch(maskActions.toggleMask(false));
          message.error(`匯出失敗：${error}`);
        }
      );
    };
  },
  /**
   * Export Gov Soil Charge Cover.
   * @param {string} projectId - The id of soil charge engineering projects
   */
  exportGovSoilChargeCover(projectId) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      reportService.exportGovSoilChargeCover({ id: projectId }).then(
        () => {
          dispatch(maskActions.toggleMask(false));
          message.success("匯出成功");
        },
        error => {
          dispatch(maskActions.toggleMask(false));
          message.error(`匯出失敗：${error}`);
        }
      );
    };
  },
  /**
   * Export Gov Soil Charge Daily Record Report.
   * @param {string} projectId - The id of soil charge engineering projects
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeDailyRecord(projectId, condition) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      reportService.exportGovSoilChargeDailyRecord({ id: projectId, ...condition }).then(
        () => {
          dispatch(maskActions.toggleMask(false));
          message.success("匯出成功");
        },
        error => {
          dispatch(maskActions.toggleMask(false));
          message.error(`匯出失敗：${error}`);
        }
      );
    };
  },
  /**
   * Export Gov Soil Charge Monthly Record Report.
   * @param {string} projectId - The id of soil charge engineering projects
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeMonthlyRecord(projectId, condition) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      reportService.exportGovSoilChargeMonthlyRecord({ id: projectId, ...condition }).then(
        () => {
          dispatch(maskActions.toggleMask(false));
          message.success("匯出成功");
        },
        error => {
          dispatch(maskActions.toggleMask(false));
          message.error(`匯出失敗：${error}`);
        }
      );
    };
  }
};
