import { history, msgTypes, showModal } from "../utils";
import { authConstants } from "../constants";
import { secureStroge, storgeKeys } from "../utils/storage.util";
import { userService } from "../services";
import { maskActions } from "./mask.actions";

export const authActions = {
  /**
   * Execute Login.
   * @param {string} email - The email of user.
   * @param {string} password - The password of user.
   * @description Contains user identity settings.
   */
  login(email, password) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .login(email, password)
        .then(
          userInfo => {
            dispatch({
              type: authConstants.LOGIN_SUCCESS,
              userInfo
            });
            history.push("/");
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
            dispatch({
              type: authConstants.LOGIN_FAILURE
            });
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Execute Third-party Login.
   * @param {object} info - The info of login.
   * @param {string} token - The token fo recaptcha.
   * @description Google、FB.
   */
  thirdpartyLogin(info) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .thirdpartyLogin(info)
        .then(
          response => {
            const { status, data } = response;
            if (status === 200) {
              dispatch({
                type: authConstants.THIRDPARTY_LOGIN_SUCCESS,
                userInfo: data
              });
              history.push("/");
            } else {
              dispatch({
                type: authConstants.INIT_THIRDPARTY_REGISTER_SUCCESS,
                thirdpartyRegisterInfo: info
              });
              history.push("/Login/ThirdpartyRegister");
            }
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
            dispatch({
              type: authConstants.THIRDPARTY_LOGIN_FAILURE
            });
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Execute Third-party Register.
   * @param {object} editInfo - The info of register.
   * @description Google、FB.
   */
  thirdpartyRegister(editInfo) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      const thirdpartyRegisterInfo = getState().auth.thirdpartyRegisterInfo;
      userService
        .thirdpartyRegister({
          ...thirdpartyRegisterInfo,
          ...editInfo
        })
        .then(
          userInfo => {
            dispatch({
              type: authConstants.THIRDPARTY_REGISTER_SUCCESS,
              userInfo
            });
            history.push("/");
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
            dispatch({
              type: authConstants.THIRDPARTY_REGISTER_FAILURE
            });
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Reset Register Info.
   */
  resetRegister() {
    return { type: authConstants.RESET_REGISTER_SUCCESS };
  },
  /**
   * Reset User Password
   * @param {object} info - The info of user's new password.
   */
  setNewPassword(info) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .setNewPassword(info)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              history.push("/Login");
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Check the validity of the login credentials.
   * @description Contains user identity settings.
   */
  check() {
    const userCredential = secureStroge.get(storgeKeys.userCredential);
    if (userCredential) {
      return dispatch => {
        dispatch(maskActions.toggleMask(true));
        userService
          .check(userCredential.uuid)
          .then(
            userInfo => {
              dispatch({
                type: authConstants.LOGIN_SUCCESS,
                userInfo
              });
            },
            error => {
              // 使用者驗證失敗
              if (error.status === 400) {
                dispatch({
                  type: authConstants.LOGIN_FAILURE
                });
                setTimeout(() => {
                  history.push("/Login");
                }, 200);
              }
            }
          )
          .then(() => {
            dispatch(maskActions.toggleMask(false));
          });
      };
    }
    history.push("/Login");
    return { type: authConstants.LOGIN_FAILURE };
  },
  /**
   * Execute Logout.
   */
  logout() {
    history.push("/Login");
    return { type: authConstants.LOGOUT };
  }
};
