import { ajax } from "../utils/ajax";

export const SMSVerificationService = {
  /**
   * Send SMS Verification Ajax Request.
   * @param {string} purpose - The purpose of SMS verification.
   * @param {string} email - The email of recipient.
   */
  send: (purpose, email) => {
    // TODO: 測試各種情境用
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         const random = Math.random();
    //         if (random > 0.5) {
    //             console.log('random > 0.5')
    //             return reject(`test`);
    //         } else if (random > 0.25) {
    //             console.log('random > 0.25')
    //             return resolve({
    //                 status: 205,
    //                 message: '請勿在五分鐘內重複發送',
    //                 data: {
    //                     remaining_seconds: 30
    //                 }
    //             })
    //         } else {
    //             console.log('random else')
    //             return resolve({
    //                 status: 200,
    //                 message: `已發送至手機號碼為 ${email} 的裝置上`,
    //                 data: {
    //                     remaining_seconds: 300
    //                 }
    //             })
    //         }
    //     }, 1000);
    // });
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/email-verifications`, {
        params: {
          purpose,
          email
        }
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 205) {
          return Promise.reject(res.message);
        }
        return res;
      });
  }
};
