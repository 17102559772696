import { ajax } from "../utils/ajax";
import { soilClassifications } from "../.fakeDatas/soilClassification";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

export const soilClassificationService = {
  /**
   * Send Get List Ajax Request.
   */
  getList() {
    // return timeoutPromise(soilClassifications.soilClassificationsGetList, 2000);
    // return timeoutPromise("載入土質分類失敗", 2000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/soil-classifications/list`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  }
};
