import { msgTypes, showModal } from "../utils";
import { userConstants, authConstants } from "../constants";
import { userService } from "../services";
import { maskActions } from "../actions";
// import { secureStroge, storgeKeys } from "../utils/storage.util";
import { reduce } from "lodash";
import moment from "moment";
import { message } from "antd";

let userLastFetchTimestamp = 0;
export const userActions = {
  /**
   * Get Users Info.
   * @param {object} condition - The condition of query.
   * @description For search grid's result.
   */
  getAll(condition) {
    return dispatch => {
      dispatch({
        type: userConstants.GET_ALL_REQUEST
      });
      userService.getAll(condition).then(
        response => {
          dispatch({
            type: userConstants.GET_ALL_SUCCESS,
            queryCondition: { ...condition },
            ...response
          });
        },
        error => {
          dispatch({
            type: userConstants.GET_ALL_FAILURE,
            error: "讀取使用者查詢結果失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Users Info
   */
  resetAll() {
    return {
      type: userConstants.RESET_ALL_SUCCESS
    };
  },
  // /**
  //  * Get Users List.
  //  * @param {object} condition - The condition of query.
  //  * @description For dropdownlist.
  //  */
  // getList(condition) {
  //   return dispatch => {
  //     dispatch({
  //       type: userConstants.GET_LIST_REQUEST
  //     });
  //     userService.getList(condition).then(
  //       users => {
  //         dispatch({
  //           type: userConstants.GET_LIST_SUCCESS,
  //           users
  //         });
  //       },
  //       error => {
  //         dispatch({
  //           type: userConstants.GET_LIST_FAILURE,
  //           error: "讀取使用者選單失敗：" + error
  //         });
  //       }
  //     );
  //   };
  // },
  /**
   * Get Users List.
   * @param {object} condition - The condition of query.
   * @param {number} execTimestamp - Execution time.
   * @param {function} cbSuccess - The callback of success.
   * @description For dropdownlist.
   */
  getList(condition, execTimestamp, cbSuccess) {
    userLastFetchTimestamp = execTimestamp;
    return dispatch => {
      dispatch({
        type: userConstants.GET_LIST_REQUEST
      });
      userService.getList(condition).then(
        users => {
          // 避免中途多次發送 Request 造成 Store 資料多次刷新
          if (execTimestamp === userLastFetchTimestamp) {
            dispatch({
              type: userConstants.GET_LIST_SUCCESS,
              users
            });

            cbSuccess && cbSuccess(users);
          }
        },
        error => {
          dispatch({
            type: userConstants.GET_LIST_FAILURE,
            error: "讀取使用者選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Get Contact User List.
   * @param {object} condition - The condition of query.
   * @param {requestCallback} cbSuccess - The callback of success.
   * @description For dropdownlist.
   */
  getContactUserList(condition, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.GET_LIST_REQUEST
      });
      userService
        .getContactUserList(condition)
        .then(
          users => {
            dispatch({
              type: userConstants.GET_LIST_SUCCESS,
              users
            });
          },
          error => {
            dispatch({
              type: userConstants.GET_LIST_FAILURE,
              error: "讀取使用者聯絡資訊選單失敗：" + error
            });
          }
        )
        .then(() => cbSuccess());
    };
  },
  /**
   * Reset Users List Info
   */
  resetList() {
    return {
      type: userConstants.RESET_LIST_SUCCESS
    };
  },
  /**
   * Get Edited Profile Info.
   * @param {requestCallback} cbError - The callback of error.
   * @description For user's profile edit.
   */
  getEditProfile(cbError) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userActions._getEdit(dispatch, userService.getProfile(), cbError);
    };
  },
  /**
   * Get Edited Info of Specific User.
   * @param {string} uuid - The uuid of user.
   * @param {requestCallback} cbError - The callback of error.
   * @description For user edit.
   */
  getEditUser(uuid, cbError) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userActions._getEdit(dispatch, userService.get(uuid), cbError);
    };
  },
  /**
   * Common Process of Get Edited User Info.
   * @param {promise} dispatch - The dispatch of call action.
   * @param {promise} promise - The promise of call service.
   * @param {requestCallback} cbError - The callback of error.
   * @description For user edit.
   */
  _getEdit(dispatch, promise, cbError) {
    promise
      .then(
        user => {
          let transUser = reduce(
            user,
            function (memo, value, key) {
              memo[key] = { value: value };
              return memo;
            },
            {}
          );
          const { birthday, roles, regions, is_editable, is_enabled } = user;
          transUser.birthday = { value: birthday ? moment(birthday) : null };
          transUser.regions = {
            value: regions && regions.length ? regions.map(region => region.id) : []
          };
          transUser.roles = {
            value: roles && roles.length ? roles.map(role => role.id.toString()) : []
          };
          transUser.is_editable = { value: is_editable ? true : false };
          transUser.is_enabled = { value: is_enabled ? true : false };
          dispatch({
            type: userConstants.GET_EDIT_SUCCESS,
            user: transUser
          });
        },
        error => {
          showModal(msgTypes.ERROR, "錯誤", error, cbError);
          dispatch({
            type: userConstants.GET_EDIT_FAILURE
          });
        }
      )
      .then(() => {
        dispatch(maskActions.toggleMask(false));
      });
  },
  /**
   * Refresh Edited User Info
   * @param {object} user - The info of user.
   * @description Support user edit.
   */
  refreshEdit(fields) {
    return {
      type: userConstants.REFRESH_EDIT_SUCCESS,
      refreshEditInfo: fields
    };
  },
  /**
   * Reset User Info
   */
  reset() {
    return {
      type: userConstants.RESET_SUCCESS
    };
  },
  /**
   * Create User.
   * @param {object} info - The info of user.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  create(info, cbSuccess) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .create(info)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, cbSuccess);
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Update User's Profile.
   * @param {object} info - The info of user.
   */
  updateProfile(info) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .updateProfile(info)
        .then(
          response => {
            const { message, data } = response;
            dispatch({
              type: authConstants.REFRESH_CREDENTIAL,
              userInfo: data
            });
            showModal(msgTypes.SUCCESS, "通知", message);
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Update Specific User.
   * @param {string} uuid - The uuid of user.
   * @param {object} info - The info of user.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  updateUser(uuid, info, cbSuccess) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .update(uuid, info)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, cbSuccess);
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Delete User.
   * @param {string} uuid - The uuid of user.
   */
  delete(uuid) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      userService
        .delete(uuid)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              const users = getState().users;
              dispatch(
                userActions.getAll(users && users.queryCondition ? users.queryCondition : null)
              );
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Switch User's Enable State.
   * @param {string} uuid - The uuid of user.
   * @param {bool} isEnable - The specify enable status.
   */
  enable(uuid, isEnable) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      userService
        .enable(uuid, isEnable)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              const users = getState().users;
              dispatch(
                userActions.getAll(users && users.queryCondition ? users.queryCondition : null)
              );
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Set Telephone of Specific User.
   * @param {string} uuid - The uuid of user.
   * @param {string} telephone - The telephone of user.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  setUserTelephone(uuid, telephone, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.SET_TELEPHONE_REQUEST
      });
      userService.setTelephone(uuid, telephone).then(
        response => {
          dispatch({
            type: userConstants.SET_TELEPHONE_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          message.success(response);
        },
        error => {
          dispatch({
            type: userConstants.SET_TELEPHONE_FAILURE,
            error: `變更主要手機失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Set Personal Telephone.
   * @param {object} info - The info of telephone.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  setPersonalTelephone(info, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.SET_TELEPHONE_REQUEST
      });
      userService.setPersonalTelephone(info).then(
        response => {
          dispatch({
            type: userConstants.SET_TELEPHONE_SUCCESS
          });
          dispatch({
            type: authConstants.REFRESH_CREDENTIAL,
            userInfo: response.data
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          message.success(response.message);
        },
        error => {
          dispatch({
            type: userConstants.SET_TELEPHONE_FAILURE,
            error: `變更主要手機失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Set Personal Backup Telephone.
   * @param {object} info - The info of backup telephone.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  setPersonalBackupTelephone(info, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.SET_TELEPHONE_REQUEST
      });
      userService.setPersonalBackupTelephone(info).then(
        response => {
          dispatch({
            type: userConstants.SET_TELEPHONE_SUCCESS
          });
          dispatch({
            type: authConstants.REFRESH_CREDENTIAL,
            userInfo: response.data
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          message.success(response.message);
        },
        error => {
          dispatch({
            type: userConstants.SET_TELEPHONE_FAILURE,
            error: `變更備用手機失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Delete Personal Backup Telephone.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  deletePersonalBackupTelephone(cbSuccess) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      userService
        .deletePersonalBackupTelephone()
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, cbSuccess);
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Reset User Telephone Setting
   */
  resetTelephoneSetting() {
    return {
      type: userConstants.RESET_TELEPHONE_SETTING
    };
  },
  /**
   * Set User Password
   * @param {object} info - The info of user's password.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  setPassword(info, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.SET_PWD_REQUEST
      });
      userService.setPassword(info).then(
        response => {
          dispatch({
            type: userConstants.SET_PWD_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          message.success(response);
        },
        error => {
          dispatch({
            type: userConstants.SET_PWD_FAILURE,
            error: `變更密碼失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Reset User Password Setting
   */
  resetPasswordSetting() {
    return {
      type: userConstants.RESET_PWD_SETTING_SUCCESS
    };
  },
  /**
   * Set User Avatar
   * @param {string} imageBase64 - The user's avatar base64 string.
   * @param {requestCallback} cbSuccess - The callback of success.
   */
  setAvatar(imageBase64, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.SET_AVATAR_REQUEST
      });
      userService.setAvatar(imageBase64).then(
        response => {
          dispatch({
            type: userConstants.SET_AVATAR_SUCCESS
          });
          dispatch({
            type: authConstants.REFRESH_CREDENTIAL,
            userInfo: response.data
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
        },
        error => {
          dispatch({
            type: userConstants.SET_AVATAR_FAILURE,
            error: `變更頭像失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Reset User Avatar
   */
  resetAvatar() {
    return {
      type: userConstants.RESET_AVATAR_SUCCESS
    };
  },
  /**
   * Force Change User Password For Anyone
   */
  forceChangePassword(uuid, info, cbSuccess) {
    return dispatch => {
      dispatch({
        type: userConstants.FORCE_CHANGE_PASSWORD_REQUEST
      });
      userService.forceChangePassword(uuid, info).then(
        response => {
          dispatch({
            type: userConstants.FORCE_CHANGE_PASSWORD_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }

          message.success(response);
        },
        error => {
          dispatch({
            type: userConstants.FORCE_CHANGE_PASSWORD_FAILURE,
            error: `變更密碼失敗：${error}`
          });
        }
      );
    };
  }
};
