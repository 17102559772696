import { ajax } from "../utils/ajax";
import excelFilePath from "../.fakeDatas/運土紀錄表.xlsx";
import pdfFilePath from "../.fakeDatas/運土紀錄表.xlsx";
import { saveAs } from "file-saver";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

const checkError = (response, fileName) => {
  return response.headers.get("content-type") === "application/json"
    ? response.json().then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message))
    : response.blob().then(blob => {
        saveAs(blob, fileName);
      });
};

export const reportService = {
  /**
   * Send Gov Soil Charge Summary Export Request.
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeSummary(condition) {
    // return fetch(pdfFilePath)
    //   .then(res => res.blob())
    //   .then(data => saveAs(data, "剩餘土石方需土數量總表.pdf"));

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/reports/gov-soil-charge-summary/export`, {
        params: condition
      })
      .then(response => checkError(response, "剩餘土石方需土數量總表.pdf"));
  },
  /**
   * Send Gov Soil Charge Cover Export Request.
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeCover(condition) {
    // return fetch(pdfFilePath)
    //   .then(res => res.blob())
    //   .then(data => saveAs(data, "剩餘土石方處理紀錄表.pdf"));

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/reports/gov-soil-charge-cover/export`, {
        params: condition
      })
      .then(response => checkError(response, "剩餘土石方處理紀錄表.pdf"));
  },
  /**
   * Send Gov Soil Charge Daily Record Export Request.
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeDailyRecord(condition) {
    // return fetch(excelFilePath)
    //   .then(res => res.blob())
    //   .then(data => saveAs(data, "收容剩餘土石方運輸日報表.xlsx"));

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/reports/gov-soil-charge-daily-records/export`, {
        params: condition
      })
      .then(response => checkError(response, "收容剩餘土石方運輸日報表.xlsx"));
  },
  /**
   * Send Gov Soil Charge Monthly Record Export Request.
   * @param {object} condition - The condition of export
   */
  exportGovSoilChargeMonthlyRecord(condition) {
    // return fetch(excelFilePath)
    //   .then(res => res.blob())
    //   .then(data => saveAs(data, "收容剩餘土石方運輸月報表.xlsx"));

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/reports/gov-soil-charge-monthly-records/export`, {
        params: condition
      })
      .then(response => checkError(response, "收容剩餘土石方運輸月報表.xlsx"));
  }
};
