import { moduleCategoryConstants } from '../constants';
import { moduleCategoryService } from '../services';

export const moduleCategoryActions = {
    /**
     * Get Module Category List.
     * @description For dropdownlist.
     */
    getList() {
        return dispatch => {
            dispatch({
                type: moduleCategoryConstants.GET_LIST_REQUEST
            });
            moduleCategoryService.getList()
                .then(
                    (moduleCategories) => {
                        dispatch({
                            type: moduleCategoryConstants.GET_LIST_SUCCESS,
                            moduleCategories
                        });
                    },
                    (error) => {
                        dispatch({
                            type: moduleCategoryConstants.GET_LIST_FAILURE,
                            error: '讀取模組分類清單結果失敗：' + error
                        });
                    }
                )
        }
    },
    /**
     * Reset Module Cateogry List Info
     */
    resetList() {
        return {
            type: moduleCategoryConstants.RESET_LIST_SUCCESS,
        };
    },
};