export const validator = {
  rules: {
    /**
     * 檢查統一編號
     * 參考：https://frank198978104.github.io/2017/09/22/idcard-and-tax-id-number-rules-for-taiwan/
     * @param {string} taxIdNumber 統一編號
     * @returns 統一編號是否有效
     */
    isTaxIdNumberValid(taxIdNumber) {
      const invalidArray = ["00000000", "11111111"];
      if (
        taxIdNumber &&
        !invalidArray.includes(taxIdNumber) &&
        validator.regulars.taxIdNumber.test(taxIdNumber)
      ) {
        const weights = [1, 2, 1, 2, 1, 2, 4, 1];
        const getTensAndUnitsDigit = num => [Math.floor(num / 10), num % 10];
        let sum = 0;
        taxIdNumber
          .toString()
          .split("")
          .forEach((idNumber, index) => {
            const [tens, units] = getTensAndUnitsDigit(idNumber * weights[index]);
            sum += tens + units;
          });

        return sum % 10 === 0;
      }
      return false;
    },
    /**
     * 檢查身份證字號
     * 參考：https://zh.wikipedia.org/wiki/中華民國國民身分證#驗證規則
     * @param {string} idNumber 身份證字號
     * @returns 身份證字號是否有效
     */
    isIdNumberValid(idNumber, regular = null) {
      if (
        idNumber && typeof idNumber === "string" && regular
          ? regular.test(idNumber.toUpperCase())
          : validator.regulars.IdNumber.test(idNumber.toUpperCase())
      ) {
        const upperCaseIdNumber = idNumber.toUpperCase();
        const cities = [
          10, 11, 12, 13, 14, 15, 16, 17, 34, 18, 19, 20, 21, 22, 35, 23, 24, 25, 26, 27, 28, 29,
          32, 30, 31, 33
        ];
        const weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
        let sum = 0;

        // 英文轉換數字
        const cityNumber = cities[upperCaseIdNumber.charCodeAt(0) - 65];
        const convertedIdNumber = cityNumber + upperCaseIdNumber.slice(1);
        const convertedIdNumberArray = convertedIdNumber.split("");

        // 計算每字權重
        convertedIdNumberArray.forEach((idNumber, index) => {
          sum += idNumber * weights[index];
        });

        return sum % 10 === 0;
      }
      return false;
    },
    /**
     * 檢查舊版居留證號
     * 參考：https://hackmd.io/@CynthiaChuang/Check-Resident-Certificate-Number
     * @param {string} idNumber 舊版居留證號
     * @returns 舊版居留證號是否有效
     */
    isOldResidenCertificateIdNumberValid(idNumber) {
      if (
        !(
          idNumber &&
          typeof idNumber === "string" &&
          validator.regulars.ResidentCertificateIdNumberOld.test(idNumber)
        )
      ) {
        return false;
      }

      let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
      let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

      idNumber =
        String(conver.indexOf(idNumber[0]) + 10) +
        String((conver.indexOf(idNumber[1]) + 10) % 10) +
        idNumber.slice(2);

      let checkSum = 0;
      for (let i = 0; i < idNumber.length; i++) {
        const c = parseInt(idNumber[i]);
        const w = weights[i];
        checkSum += c * w;
      }

      return checkSum % 10 === 0;
    },
    /**
     * 檢查新版居留證號
     * 參考：https://hackmd.io/@CynthiaChuang/Check-Resident-Certificate-Number
     * @param {string} idNumber 新版居留證號
     * @returns 新版居留證號是否有效
     */
    isNewResidenCertificateIdNumberValid(idNumber) {
      return validator.rules.isIdNumberValid(idNumber, validator.regulars.ResidentCertificateIdNumberNew);
    }
  },
  regulars: {
    /**
     * 統一編號格式
     */
    taxIdNumber: new RegExp("^[\\d]{8}$"),
    /**
     * 身份證字號格式
     */
    IdNumber: new RegExp("^[A-Z]{1}[1|2]{1}[\\d]{8}$"),
    /**
     * 舊版居留證號格式
     */
    ResidentCertificateIdNumberOld: new RegExp("^[A-Z][ABCD]\\d{8}$"),
    /**
     * 新版居留證號格式
     */
    ResidentCertificateIdNumberNew: new RegExp("^[A-Z]{1}[8|9]{1}[\\d]{8}$")
  }
};
