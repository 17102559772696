import { soilClassificationService } from "../services";
import { soilClassificationConstants } from "../constants";

export const soilClassificationActions = {
  /**
   * Get Soil Classification List.
   * @description For dropdownlist.
   */
  getList() {
    return dispatch => {
      dispatch({
        type: soilClassificationConstants.GET_LIST_REQUEST
      });
      soilClassificationService.getList().then(
        soilClassficiations => {
          dispatch({
            type: soilClassificationConstants.GET_LIST_SUCCESS,
            soilClassficiations
          });
        },
        error => {
          dispatch({
            type: soilClassificationConstants.GET_LIST_FAILURE,
            error: "讀取土質類別選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Soil Classification List Info
   */
  resetList() {
    return {
      type: soilClassificationConstants.RESET_LIST_SUCCESS
    };
  }
};
