const driverRegistrationsGetAll = [
  {
    id: 1,
    id_number: "D134936794",
    car_number: "CBA-359",
    driver: "陳曉天",
    drive_count: 38
  },
  {
    id: 2,
    id_number: "D892177366",
    car_number: "BBC-899",
    driver: "黃大銘",
    drive_count: 61
  },
  {
    id: 3,
    id_number: "D294400573",
    car_number: "BCB-816",
    driver: "許明義",
    drive_count: 37
  },
  {
    id: 4,
    id_number: "D601077807",
    car_number: "ACC-376",
    driver: "陳天人",
    drive_count: 0
  },
  {
    id: 5,
    id_number: "D821408350",
    car_number: "ABC-489",
    driver: "吳康人",
    drive_count: 1189
  }
];

export const driverRegistrations = {
  driverRegistrationsGetAll: {
    total: driverRegistrationsGetAll.length,
    items: driverRegistrationsGetAll
  },
  driverRegistrationEdit(id) {
    return driverRegistrationsGetAll.find(d => d.id === id);
  },
  driverRegistrationsGetList: driverRegistrationsGetAll
};
