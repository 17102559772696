export const roleConstants = {
    // GET ALL: 查詢選單用
    GET_ALL_REQUEST: 'ROLE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'ROLE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'ROLE_GET_ALL_FAILURE',
    RESET_ALL_SUCCESS: 'ROLE_RESET_ALL_SUCCESS',
    RESET_SUCCESS: 'ROLE_RESET_SUCCESS',
    // GET LIST: 取得清單用
    GET_LIST_REQUEST: 'ROLE_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'ROLE_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'ROLE_GET_LIST_FAILURE',
    RESET_LIST_SUCCESS: 'ROLE_RESET_LIST_SUCCESS',
    // SET EDITED: 設定編輯資料用
    GET_EDIT_SUCCESS: 'ROLE_GET_EDIT_SUCCESS',
    GET_EDIT_FAILURE: 'ROLE_GET_EDIT_FAILURE',
    REFRESH_EDIT_SUCCESS: 'ROLE_REFRESH_EDIT_SUCCESS',
    REFRESH_EDIT_PERMISSION_SUCCESS: 'ROLE_REFRESH_EDIT_PERMISSION_SUCCESS',
};