import { Modal } from "antd";

/**
 * Message type enums.
 */
export const msgTypes = Object.freeze({
  INFO: Symbol("info"),
  SUCCESS: Symbol("success"),
  ERROR: Symbol("error"),
  WARNING: Symbol("warning"),
  CONFIRM: Symbol("confirm"),
  CONFIRM_DANGER: Symbol("confirm-danger")
});

/**
 * Show message with modal.
 * @param {msgTypes} type - The message type of modal.
 * @param {string} title - The title of modal.
 * @param {string} content - The content of modal.
 * @param {requestCallback} cbOk - The callback ok function.
 * @param {requestCallback} cbCancel - The callback cancel function.
 */
export const showModal = (type, title, content, cbOk, cbCancel, options) => {
  let modal;
  let okButtonProps = {};
  switch (type) {
    case msgTypes.SUCCESS:
      modal = Modal.success;
      break;
    case msgTypes.ERROR:
      modal = Modal.error;
      break;
    case msgTypes.WARNING:
      modal = Modal.warning;
      break;
    case msgTypes.CONFIRM_DANGER:
      okButtonProps = { danger: true };
      modal = Modal.confirm;
      break;
    case msgTypes.CONFIRM:
      modal = Modal.confirm;
      break;
    default:
      modal = Modal.info;
      break;
  }
  modal({
    zIndex: 1050,
    title: title ? title : "通知",
    content: content ? content : "訊息",
    okText: "確定",
    okType: "primary",
    okButtonProps: okButtonProps,
    onOk() {
      if (cbOk && typeof cbOk === "function") {
        cbOk();
      }
    },
    cancelText: "取消",
    onCancel() {
      if (cbCancel && typeof cbCancel === "function") {
        cbCancel();
      }
    },
    ...options
  });
};
