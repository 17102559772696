const soilTransportRecordsGetAll = [
  {
    id: 1,
    transport_at: "2000-04-19 19:56:30",
    soil_supply_engineering_id: 1,
    soil_supply_engineering_name: "Micronesia",
    soil_supply_engineering_control_number: "A54266",
    serial_number: "ACCD05-00001",
    driver_registration_id: 1,
    driver_registration_driver: "Jensen",
    driver_registration_id_number: "A358529945",
    driver_registration_car_number: "BBC1890",
    supervision_vendor: "Shaffer",
    supervision_vendor_principal: "Sullivan",
    supervision_vendor_principal_phone: 9884083421,
    contract_vendor_id: 5,
    contract_vendor: "Vaughan",
    contract_vendor_member_id: 1,
    contract_vendor_member: "Leblanc",
    contract_vendor_member_phone: 9274362972,
    transport_vendor_id: 5,
    transport_vendor: "Lopez",
    transport_vendor_principal: "Cabrera",
    transport_vendor_principal_phone: 9674313894,
    start_date: "1995-11-16 08:45:27",
    end_date: "2005-10-23 17:25:07",
    transport_cost: 4552687,
    soil_supply_classification_id: 2,
    soil_supply_classification_name: "C1",
    recycle_price_per_ton: 8088,
    transport_count_by_ton: 3124,
    transport_count_by_cb: 6499,
    pagination: {
      prev: null,
      next: 2
    }
  },
  {
    id: 2,
    transport_at: "1986-08-02 15:32:23",
    soil_supply_engineering_id: 2,
    soil_supply_engineering_name: "Angola",
    soil_supply_engineering_control_number: "C54006",
    serial_number: "ACCD05-00002",
    driver_registration_id: 3,
    driver_registration_driver: "Robyn",
    driver_registration_id_number: "C450431679",
    driver_registration_car_number: "BBC9544",
    supervision_vendor: "Jodi",
    supervision_vendor_principal: "David",
    supervision_vendor_principal_phone: 8874382880,
    contract_vendor_id: 1,
    contract_vendor: "Angela",
    contract_vendor_member_id: 2,
    contract_vendor_member: "Maribel",
    contract_vendor_member_phone: 8905162448,
    transport_vendor_id: 1,
    transport_vendor: "Mueller",
    transport_vendor_principal: "Joanne",
    transport_vendor_principal_phone: 8155992139,
    start_date: "1978-05-06 15:17:07",
    end_date: "2001-10-11 20:58:04",
    transport_cost: 5404295,
    soil_supply_classification_id: 3,
    soil_supply_classification_name: "T1",
    recycle_price_per_ton: 4762,
    transport_count_by_ton: 8615,
    transport_count_by_cb: 1087,
    pagination: {
      prev: 1,
      next: 3
    }
  },
  {
    id: 3,
    transport_at: "2002-05-20 01:49:04",
    soil_supply_engineering_id: 1,
    soil_supply_engineering_name: "Laos",
    soil_supply_engineering_control_number: "C72128",
    serial_number: "ACCD05-00003",
    driver_registration_id: 1,
    driver_registration_driver: "Helen",
    driver_registration_id_number: "C170500242",
    driver_registration_car_number: "CAB3525",
    supervision_vendor: "Sara",
    supervision_vendor_principal: "Shawn",
    supervision_vendor_principal_phone: 8545752272,
    contract_vendor_id: 2,
    contract_vendor: "Gilbert",
    contract_vendor_member_id: 1,
    contract_vendor_member: "Chen",
    contract_vendor_member_phone: 9955482359,
    transport_vendor_id: 3,
    transport_vendor: "Holloway",
    transport_vendor_principal: "Katheryn",
    transport_vendor_principal_phone: 9524053233,
    start_date: "1986-07-14 12:31:08",
    end_date: "2003-08-05 07:54:34",
    transport_cost: 6185112,
    soil_supply_classification_id: 1,
    soil_supply_classification_name: "T1",
    recycle_price_per_ton: 5552,
    transport_count_by_ton: 6436,
    transport_count_by_cb: 8854,
    pagination: {
      prev: 2,
      next: 4
    }
  },
  {
    id: 4,
    transport_at: "1986-12-01 14:28:56",
    soil_supply_engineering_id: 1,
    soil_supply_engineering_name: "Sierra Leone",
    soil_supply_engineering_control_number: "A31316",
    serial_number: "ACCD05-00004",
    driver_registration_id: 1,
    driver_registration_driver: "Lela",
    driver_registration_id_number: "B492123699",
    driver_registration_car_number: "BBC9338",
    supervision_vendor: "Darlene",
    supervision_vendor_principal: "Lawrence",
    supervision_vendor_principal_phone: 8194492232,
    contract_vendor_id: 1,
    contract_vendor: "Oconnor",
    contract_vendor_member_id: 5,
    contract_vendor_member: "Tamra",
    contract_vendor_member_phone: 9055273382,
    transport_vendor_id: 4,
    transport_vendor: "Holmes",
    transport_vendor_principal: "Keith",
    transport_vendor_principal_phone: 9225993430,
    start_date: "2017-07-20 20:21:28",
    end_date: "1973-05-04 14:32:44",
    transport_cost: 1165387,
    soil_supply_classification_id: 2,
    soil_supply_classification_name: "B1",
    recycle_price_per_ton: 4483,
    transport_count_by_ton: 4073,
    transport_count_by_cb: 4302,
    pagination: {
      prev: 3,
      next: 5
    }
  },
  {
    id: 5,
    transport_at: "1983-03-18 18:49:24",
    soil_supply_engineering_id: 1,
    soil_supply_engineering_name: "British Indian Ocean Territory",
    soil_supply_engineering_control_number: "A32763",
    serial_number: "ACCD05-00005",
    driver_registration_id: 1,
    driver_registration_driver: "Bobbie",
    driver_registration_id_number: "B484999090",
    driver_registration_car_number: "BBA4236",
    supervision_vendor: "Chasity",
    supervision_vendor_principal: "Lacey",
    supervision_vendor_principal_phone: 8104873454,
    contract_vendor_id: 5,
    contract_vendor: "Mcguire",
    contract_vendor_member_id: 5,
    contract_vendor_member: "Edwina",
    contract_vendor_member_phone: 9244982566,
    transport_vendor_id: 1,
    transport_vendor: "Lowery",
    transport_vendor_principal: "Hooper",
    transport_vendor_principal_phone: 8715102129,
    start_date: "2017-03-21 15:41:02",
    end_date: "2020-08-16 15:34:34",
    transport_cost: 6378947,
    soil_supply_classification_id: 2,
    soil_supply_classification_name: "T1",
    recycle_price_per_ton: 8899,
    transport_count_by_ton: 7091,
    transport_count_by_cb: 2164,
    pagination: {
      prev: 4,
      next: null
    }
  }
];

export const soilTransportRecords = {
  soilTransportRecordsGetAll: {
    total: soilTransportRecordsGetAll.length,
    items: soilTransportRecordsGetAll
  },
  soilTransportRecordsEdit(id) {
    return soilTransportRecordsGetAll.find(item => item.id === id);
  },
  soilTransportRecordsGetList: soilTransportRecordsGetAll
};
