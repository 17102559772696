import { combineReducers } from "redux";
import { mask } from "./mask.reducer";
import { auth } from "./auth.reducer";
import { user } from "./user.reducer";
import { users } from "./users.reducer";
import { role } from "./role.reducer";
import { roles } from "./roles.reducer";
import { moduleCategories } from "./moduleCategories.reducer";
import { modules } from "./modules.reducer";
import { module } from "./module.reducer";
import { SMSVerification } from "./SMSVerification.reducer";
import { vendors } from "./vendors.reducer";
import { vendor } from "./vendor.reducer";
import { driverRegistrations } from "./driverRegistrations.reducer";
import { driverRegistration } from "./driverRegistration.reducer";
import { engineeringClassifications } from "./engineeringClassifications.reducer";
import { soilChargeEngineeringProject } from "./soilChargeEngineeringProject.reducer";
import { soilChargeEngineeringProjects } from "./soilChargeEngineeringProjects.reducer";
import { soilSupplyEngineering } from "./soilSupplyEngineering.reducer";
import { soilSupplyEngineerings } from "./soilSupplyEngineerings.reducer";
import { soilClassifications } from "./soilClassifications.reducer";
import { soilTransportRecord } from "./soilTransportRecord.reducer";
import { soilTransportRecords } from "./soilTransportRecords.reducer";

const rootReducer = combineReducers({
  mask,
  auth,
  user,
  users,
  role,
  roles,
  moduleCategories,
  modules,
  module,
  SMSVerification,
  vendors,
  vendor,
  driverRegistrations,
  driverRegistration,
  engineeringClassifications,
  soilChargeEngineeringProjects,
  soilChargeEngineeringProject,
  soilSupplyEngineerings,
  soilSupplyEngineering,
  soilClassifications,
  soilTransportRecords,
  soilTransportRecord
  // ... todo
});

export default rootReducer;
