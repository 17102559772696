import { ajax } from "../utils/ajax";
import { soilSupplyEngineerings } from "../.fakeDatas/soilSupplyEngineering";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

export const soilSupplyEngineeringService = {
  /**
   * Send Get All Soil Supply Engineering Ajax Request.
   * @param {string} projectId - The id of soil charge engineering project.
   * @param {object} condition - The condition of query.
   */
  getAll(projectId, condition) {
    // return timeoutPromise(soilSupplyEngineerings.soilSupplyEngineeringsGetAll, 1000);
    // return timeoutPromise("查詢失敗", 1000, true);

    return ajax
      .get(
        `${process.env.REACT_APP_API_URL}/soil-charge-engineering-projects/${projectId}/soil-supply-engineerings`,
        {
          params: {
            ...condition
          }
        }
      )
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get Soil Supply Engineering List Ajax Request.
   * @param {string} projectId - The id of soil charge engineering project.
   */
  getList(projectId) {
    // return timeoutPromise(soilSupplyEngineerings.soilSupplyEngineeringsGetList, 1000);
    return ajax
      .get(
        `${process.env.REACT_APP_API_URL}/soil-charge-engineering-projects/${projectId}/soil-supply-engineerings/list`
      )
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get Soil Supply Engineering Info Ajax Request.
   * @param {string} id - The id of soil supply engineering.
   */
  get(id) {
    // return timeoutPromise(soilSupplyEngineerings.soilSupplyEngineeringsEdit(id), 1000);
    // return timeoutPromise("讀取出土工程失敗", 1000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/soil-supply-engineerings/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Create Soil Supply Engineering Ajax Request.
   * @param {object} info - The info of soil supply engineering type.
   */
  create(info) {
    // return timeoutPromise("新增成功", 1000);

    return ajax
      .post(`${process.env.REACT_APP_API_URL}/soil-supply-engineerings`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Update Soil Supply Engineering Ajax Request.
   * @param {string} id - The id of soil supply engineering.
   * @param {object} info - The info of soil supply engineering.
   */
  update(id, info) {
    // return timeoutPromise("修改成功", 1000);

    return ajax
      .put(`${process.env.REACT_APP_API_URL}/soil-supply-engineerings/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Delete Soil Supply Engineering Ajax Request.
   * @param {string} id - The id of soil supply engineering.
   */
  delete(id) {
    // return timeoutPromise("刪除成功", 1000);

    return ajax
      .delete(`${process.env.REACT_APP_API_URL}/soil-supply-engineerings/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  }
};
