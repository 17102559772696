import { soilSupplyEngineeringConstants } from "../constants";
import { v4 as uuidv4 } from "uuid";

const getFieldName = field => {
  // 第一個屬性即欄位名稱
  return Object.getOwnPropertyNames(field)[0];
};

const initState = {
  edit: {},
  save: {}
};

export const soilSupplyEngineering = (state = initState, action) => {
  switch (action.type) {
    case soilSupplyEngineeringConstants.GET_EDITED_REQUEST:
      return {
        ...state,
        edit: {
          loading: true
        }
      };
    case soilSupplyEngineeringConstants.GET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: action.soilSupplyEngineering,
          error: null
        }
      };
    case soilSupplyEngineeringConstants.GET_EDITED_FAILURE:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: null,
          error: action.error
        }
      };
    case soilSupplyEngineeringConstants.RESET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {},
        save: {}
      };
    case soilSupplyEngineeringConstants.REFRESH_EDITED_SUCCESS:
      // 初始 refresh
      if (!state.edit.editInfo) {
        return {
          ...state,
          edit: {
            editInfo: {
              ...action.refreshEditInfo
            }
          }
        };
      }

      // 處理載運土質的 refresh
      let newSoilSupplyClassifications = [
        ...(state.edit.editInfo.soil_supply_classifications || [])
      ];
      const { soil_supply_classifications } = action.refreshEditInfo;
      if (soil_supply_classifications) {
        newSoilSupplyClassifications = newSoilSupplyClassifications.map(
          (classification, index) => ({
            ...classification,
            ...(soil_supply_classifications[index] ? soil_supply_classifications[index] : {})
          })
        );
      }

      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            ...action.refreshEditInfo,
            soil_supply_classifications: newSoilSupplyClassifications
          }
        }
      };
    case soilSupplyEngineeringConstants.REMOVE_SOIL_CLASSIFICATION: {
      const { soilClassificationId } = action;
      const { soil_supply_classifications = [] } = state.edit.editInfo;
      const index = soil_supply_classifications.findIndex(
        classification => classification.id === soilClassificationId
      );
      const newSoilSupplyClassifications = [
        ...soil_supply_classifications.slice(0, index),
        ...soil_supply_classifications.slice(index + 1, soil_supply_classifications.length)
      ];

      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            soil_supply_classifications: newSoilSupplyClassifications
          }
        }
      };
    }
    case soilSupplyEngineeringConstants.ADD_SOIL_CLASSIFICATION: {
      const { soil_supply_classifications = [] } = state.edit.editInfo;
      const newSoilSupplyClassifications = [
        ...soil_supply_classifications,
        {
          id: uuidv4(),
          soil_classification_id: { value: undefined },
          default_recycle_price_per_ton: { value: undefined },
          is_allow_delete: true,
          is_new: true
        }
      ];

      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            soil_supply_classifications: newSoilSupplyClassifications
          }
        }
      };
    }
    case soilSupplyEngineeringConstants.SET_EDITED_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      };
    case soilSupplyEngineeringConstants.SET_EDITED_SUCCESS:
      return {
        ...state,
        save: {
          processing: false
        }
      };
    case soilSupplyEngineeringConstants.SET_EDITED_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
