export const soilTransportRecordConstants = {
  // 取得清單資料
  GET_ALL_REQUEST: "SOIL_TRANSPORT_RECORD_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "SOIL_TRANSPORT_RECORD_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "SOIL_TRANSPORT_RECORD_GET_ALL_FAILURE",
  RESET_ALL_SUCCESS: "SOIL_TRANSPORT_RECORD_RESETALL_SUCCESS",
  // 取得編輯中資料
  GET_EDITED_REQUEST: "SOIL_TRANSPORT_RECORD_GET_EDITED_REQUEST",
  GET_EDITED_SUCCESS: "SOIL_TRANSPORT_RECORD_GET_EDITED_SUCCESS",
  GET_EDITED_FAILURE: "SOIL_TRANSPORT_RECORD_GET_EDITED_FAILURE",
  RESET_EDITED_SUCCESS: "SOIL_TRANSPORT_RECORD_RESET_EDITED_SUCCESS",
  REFRESH_EDITED_SUCCESS: "SOIL_TRANSPORT_RECORD_REFRESH_EDITED_SUCCESS",
  // 儲存編輯資料用
  SET_EDITED_REQUEST: "SOIL_TRANSPORT_RECORD_SET_EDITED_REQUEST",
  SET_EDITED_SUCCESS: "SOIL_TRANSPORT_RECORD_SET_EDITED_SUCCESS",
  SET_EDITED_FAILURE: "SOIL_TRANSPORT_RECORD_SET_EDITED_FAILURE",
  // 取得檢視資料
  GET_VIEW_REQUEST: "SOIL_TRANSPORT_RECORD_GET_VIEW_REQUEST",
  GET_VIEW_SUCCESS: "SOIL_TRANSPORT_RECORD_GET_VIEW_SUCCESS",
  GET_VIEW_FAILURE: "SOIL_TRANSPORT_RECORD_GET_VIEW_FAILURE",
  RESET_VIEW_SUCCESS: "SOIL_TRANSPORT_RECORD_RESET_VIEW_SUCCESS"
};
