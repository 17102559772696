import { soilChargeEngineeringProjectService } from "../services";
import { soilChargeEngineeringProjectConstants } from "../constants";
import { maskActions } from ".";
import { reduce } from "lodash";
import { msgTypes, showModal } from "../utils";
import { message } from "antd";
import moment from "moment";

export const soilChargeEngineeringProjectActions = {
  /**
   * Get Soil Charge Engineering Project Info.
   * @description For search grid's result.
   */
  getAll() {
    return dispatch => {
      dispatch({
        type: soilChargeEngineeringProjectConstants.GET_ALL_REQUEST
      });
      soilChargeEngineeringProjectService.getAll().then(
        response => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.GET_ALL_SUCCESS,
            queryCondition: {},
            ...response
          });
        },
        error => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.GET_ALL_FAILURE,
            error: "讀取需土工程專案查詢結果失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Soil Charge Engineering Project Info
   */
  resetAll() {
    return {
      type: soilChargeEngineeringProjectConstants.RESET_ALL_SUCCESS
    };
  },
  /**
   * Get Soil Charge Engineering Project List.
   * @description For dropdownlist.
   */
  getList() {
    return dispatch => {
      dispatch({
        type: soilChargeEngineeringProjectConstants.GET_LIST_REQUEST
      });
      soilChargeEngineeringProjectService.getList().then(
        soilChargeEngineeringProjects => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.GET_LIST_SUCCESS,
            soilChargeEngineeringProjects
          });
        },
        error => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.GET_LIST_FAILURE,
            error: "讀取需土工程專案選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Soil Charge Engineering Project List Info
   */
  resetList() {
    return {
      type: soilChargeEngineeringProjectConstants.RESET_LIST_SUCCESS
    };
  },
  /**
   * Get Edited Soil Charge Engineering Project Info.
   * @param {string} id - The id of soil charge engineering project.
   * @description For Soil Charge Engineering Project edit.
   */
  getEdited(id) {
    return dispatch => {
      dispatch(maskActions.toggleMask(true));
      dispatch({ type: soilChargeEngineeringProjectConstants.GET_EDITED_REQUEST });
      soilChargeEngineeringProjectService
        .get(id)
        .then(
          project => {
            let transProject = reduce(
              project,
              function (memo, value, key) {
                memo[key] = { value: value };
                return memo;
              },
              {}
            );

            const { start_date, end_date } = project;

            transProject.entry_date_range = {
              value: [moment(start_date), moment(end_date)]
            };

            dispatch({
              type: soilChargeEngineeringProjectConstants.GET_EDITED_SUCCESS,
              project: transProject
            });
          },
          error => {
            dispatch({
              type: soilChargeEngineeringProjectConstants.GET_EDITED_FAILURE,
              error
            });
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Create Soil Charge Engineering Project.
   * @param {object} info - The info of soil charge engineering project.
   * @param {function} cbSuccess - The callback of success.
   */
  create(info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: soilChargeEngineeringProjectConstants.SET_EDITED_REQUEST
      });
      soilChargeEngineeringProjectService.create(info).then(
        response => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          soilChargeEngineeringProjectActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.SET_EDITED_FAILURE,
            error: `新增需土工程專案資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Update Soil Charge Engineering Project.
   * @param {string} id - The id of soil charge engineering project.
   * @param {object} info - The info of soil charge engineering project.
   * @param {function} cbSuccess - The callback of success.
   */
  update(id, info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: soilChargeEngineeringProjectConstants.SET_EDITED_REQUEST
      });
      soilChargeEngineeringProjectService.update(id, info).then(
        response => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          soilChargeEngineeringProjectActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          dispatch({
            type: soilChargeEngineeringProjectConstants.SET_EDITED_FAILURE,
            error: `修改需土工程專案資料失敗：${error}`
          });
        }
      );
    };
  },
  /**
   * Delete Soil Charge Engineering Project.
   * @param {string} id - The id of soil charge engineering project.
   */
  delete(id) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      soilChargeEngineeringProjectService
        .delete(id)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              soilChargeEngineeringProjectActions.reloadGetAll(dispatch, getState);
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Refresh Edited Soil Charge Engineering Project Info.
   * @param {object} fields - The info of soil charge engineering project.
   * @description Support Soil Charge Engineering Project edit.
   */
  refreshEdited(fields) {
    return {
      type: soilChargeEngineeringProjectConstants.REFRESH_EDITED_SUCCESS,
      refreshEditInfo: fields
    };
  },
  /**
   * Reset Soil Charge Engineering Project Info.
   */
  reset() {
    return {
      type: soilChargeEngineeringProjectConstants.RESET_EDITED_SUCCESS
    };
  },
  reloadGetAll(dispatch, getState) {
    dispatch(soilChargeEngineeringProjectActions.getAll());
  }
};
