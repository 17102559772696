import { soilTransportRecordConstants } from "../constants";

const initState = {
  query: {}
};

export const soilTransportRecords = (state = initState, action) => {
  switch (action.type) {
    case soilTransportRecordConstants.GET_ALL_REQUEST:
      return {
        ...state,
        query: {
          loading: true
        }
      };
    case soilTransportRecordConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        query: {
          projectId: action.projectId,
          queryCondition: action.queryCondition,
          total: action.total,
          queryResult: action.items
        }
      };
    case soilTransportRecordConstants.GET_ALL_FAILURE:
      return {
        ...state,
        query: {
          queryFail: action.error
        }
      };
    case soilTransportRecordConstants.RESET_ALL_SUCCESS:
      return {
        ...state,
        query: {}
      };
    default:
      return state;
  }
};
