export const userConstants = {
  // 取得使用者編輯中資料
  GET_EDIT_SUCCESS: "USER_GET_EDIT_SUCCESS",
  GET_EDIT_FAILURE: "USER_GET_EDIT_FAILURE",
  REFRESH_EDIT_SUCCESS: "USER_REFRESH_EDIT_SUCCESS",
  // 取得使用者資料清單
  GET_ALL_REQUEST: "USER_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "USER_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "USER_GET_ALL_FAILURE",
  RESET_ALL_SUCCESS: "USER_RESETALL_SUCCESS",
  // 取得使用者列表
  GET_LIST_REQUEST: "USER_GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "USER_GET_LIST_SUCCESS",
  GET_LIST_FAILURE: "USER_GET_LIST_FAILURE",
  RESET_LIST_SUCCESS: "USER_RESET_LIST_SUCCESS",
  // 設定指定使用者或個人的手機
  SET_TELEPHONE_REQUEST: "USER_SET_TELEPHONE_REQUEST",
  SET_TELEPHONE_SUCCESS: "USER_SET_TELEPHONE_SUCCESS",
  SET_TELEPHONE_FAILURE: "USER_SET_TELEPHONE_FAILURE",
  RESET_TELEPHONE_SETTING: "USER_RESET_TELEPHONE_SETTING",
  // 設定密碼
  SET_PWD_REQUEST: "USER_SET_PWD_REQUEST",
  SET_PWD_SUCCESS: "USER_SET_PWD_SUCCESS",
  SET_PWD_FAILURE: "USER_SET_PWD_FAILURE",
  RESET_PWD_SETTING_SUCCESS: "USER_RESET_PWD_SETTING_SUCCESS",
  // 設定大頭貼
  SET_AVATAR_REQUEST: "USER_SET_AVATAR_REQUEST",
  SET_AVATAR_SUCCESS: "USER_SET_AVATAR_SUCCESS",
  SET_AVATAR_FAILURE: "USER_SET_AVATAR_FAILURE",
  RESET_AVATAR_SUCCESS: "USER_RESET_AVATAR_SUCCESS",
  RESET_SUCCESS: "USER_RESET_SUCCESS",
  // 強制變更密碼
  FORCE_CHANGE_PASSWORD_REQUEST: "USER_SET_FORCE_CHANGE_PASSWORD_REQUEST",
  FORCE_CHANGE_PASSWORD_SUCCESS: "USER_SET_FORCE_CHANGE_PASSWORD_SUCCESS",
  FORCE_CHANGE_PASSWORD_FAILURE: "USER_SET_FORCE_CHANGE_PASSWORD_FAILURE",
  RESET_FORCE_CHANGE_PASSWORD: "USER_RESET_FORCE_CHANGE_PASSWORD"
};
