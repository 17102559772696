import { soilTransportRecordConstants } from "../constants";

const initState = {
  edit: {},
  view: {},
  save: {}
};

export const soilTransportRecord = (state = initState, action) => {
  switch (action.type) {
    case soilTransportRecordConstants.GET_EDITED_REQUEST:
      return {
        ...state,
        edit: {
          loading: true
        }
      };
    case soilTransportRecordConstants.GET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: action.soilTransportRecord,
          error: null
        }
      };
    case soilTransportRecordConstants.GET_EDITED_FAILURE:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: null,
          error: action.error
        }
      };
    case soilTransportRecordConstants.RESET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {},
        save: {}
      };
    case soilTransportRecordConstants.REFRESH_EDITED_SUCCESS:
      // 初始 refresh
      if (!state.edit.editInfo) {
        return {
          ...state,
          edit: {
            editInfo: {
              ...action.refreshEditInfo
            }
          }
        };
      }

      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            ...action.refreshEditInfo
          }
        }
      };
    case soilTransportRecordConstants.SET_EDITED_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      };
    case soilTransportRecordConstants.SET_EDITED_SUCCESS:
      return {
        ...state,
        save: {
          processing: false
        }
      };
    case soilTransportRecordConstants.SET_EDITED_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          error: action.error
        }
      };
    case soilTransportRecordConstants.GET_VIEW_REQUEST:
      return {
        ...state,
        view: {
          loading: true
        }
      };
    case soilTransportRecordConstants.GET_VIEW_SUCCESS:
      return {
        ...state,
        view: {
          loading: false,
          viewInfo: action.soilTransportRecord,
          error: null
        }
      };
    case soilTransportRecordConstants.GET_VIEW_FAILURE:
      return {
        ...state,
        view: {
          loading: false,
          viewInfo: null,
          error: action.error
        }
      };
    case soilTransportRecordConstants.RESET_VIEW_SUCCESS:
      return {
        ...state,
        view: {}
      };
    default:
      return state;
  }
};
