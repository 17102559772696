import { vendorConstants } from "../constants";
import { v4 as uuidv4 } from "uuid";

const getFieldName = field => {
  // 第一個屬性即欄位名稱
  return Object.getOwnPropertyNames(field)[0];
};

const initState = {
  edit: {},
  save: {}
};

export const vendor = (state = initState, action) => {
  switch (action.type) {
    case vendorConstants.GET_EDITED_REQUEST:
      return {
        ...state,
        edit: {
          loading: true
        }
      };
    case vendorConstants.GET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: action.vendor,
          oriVendor: action.oriVendor,
          error: null
        }
      };
    case vendorConstants.GET_EDITED_FAILURE:
      return {
        ...state,
        edit: {
          loading: false,
          editInfo: null,
          error: action.error
        }
      };
    case vendorConstants.RESET_EDITED_SUCCESS:
      return {
        ...state,
        edit: {}
      };
    case vendorConstants.REFRESH_EDITED_SUCCESS:
      // 初始 refresh
      if (!state.edit.editInfo) {
        return {
          ...state,
          edit: {
            ...state.edit,
            editInfo: {
              ...action.refreshEditInfo
            }
          }
        };
      }

      // 處理聯絡人欄位的 refresh
      let newMembers = [...(state.edit.editInfo.members || [])];
      const { members } = action.refreshEditInfo;
      if (members) {
        newMembers = newMembers.map((newMember, index) => ({
          ...newMember,
          ...(members[index] ? members[index] : {})
        }));
      }

      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            ...action.refreshEditInfo,
            members: newMembers
          }
        }
      };
    case vendorConstants.SET_EDITED_REQUEST:
      return {
        ...state,
        save: {
          processing: true
        }
      };
    case vendorConstants.SET_EDITED_SUCCESS:
      return {
        ...state,
        save: {
          processing: false
        }
      };
    case vendorConstants.SET_EDITED_FAILURE:
      return {
        ...state,
        save: {
          processing: false,
          error: action.error
        }
      };
    case vendorConstants.REMOVE_MEMBER: {
      const { memberId } = action;
      const { members = [] } = state.edit.editInfo;
      const index = members.findIndex(member => member.id === memberId);
      const newMembers = [...members.slice(0, index), ...members.slice(index + 1, members.length)];
      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            members: newMembers
          }
        }
      };
    }
    case vendorConstants.ADD_MEMBER: {
      const { members = [] } = state.edit.editInfo;
      const newId = uuidv4();
      const newMembers = [
        ...members,
        {
          oriId: newId,
          id: { value: newId },
          name: { value: null },
          phone: { value: null },
          is_allow_delete: true,
          is_new: true
        }
      ];
      return {
        ...state,
        edit: {
          ...state.edit,
          editInfo: {
            ...state.edit.editInfo,
            members: newMembers
          }
        }
      };
    }
    default:
      return state;
  }
};
