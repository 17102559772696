const soilSupplyEngineeringsGetAll = [
  {
    id: 1,
    engineering_classification_id: 1,
    engineering_classification: "建築工程",
    control_number: "BAB3845",
    engineering_number: "(107)府交工字第35號",
    name: "出土工程1",
    location: "Ireland Glasgow Utica Avenue",
    host_vendor_id: 5,
    host_vendor: "Kirsten",
    host_vendor_member_id: 3,
    host_vendor_member: "Henrietta",
    host_vendor_member_phone: 9555913818,
    supervision_vendor_id: 4,
    supervision_vendor: "Elva",
    supervision_vendor_principal: "Allie",
    supervision_vendor_principal_phone: 9774152168,
    contract_vendor_id: 4,
    contract_vendor: "Salas",
    contract_vendor_principal: "CDSD2w",
    contract_vendor_principal_phone: "09223346#123",
    contract_vendor_member_id: 2,
    contract_vendor_member: "Guadalupe",
    contract_vendor_member_phone: 8504593901,
    transport_vendor_id: 1,
    transport_vendor: "Mason",
    transport_vendor_principal: "Atkinson",
    transport_vendor_principal_phone: 9394793357,
    default_transport_cost: 4747197,
    default_transport_count_by_cb: 12,
    default_transport_cost: 3500,
    driver_count: 100,
    soil_supply_classifications: [
      {
        id: 1,
        soil_classification_id: 2,
        soil_classification_name: "T1",
        current_soil_cb_count: 882102,
        current_soil_ton_count: 258324,
        current_drive_count: 53,
        default_recycle_price_per_ton: 60675,
        is_allow_delete: true
      },
      {
        id: 2,
        soil_classification_id: 3,
        soil_classification_name: "B1",
        current_soil_cb_count: 261794,
        current_soil_ton_count: 682498,
        current_drive_count: 62,
        default_recycle_price_per_ton: 61932,
        is_allow_delete: true
      },
      {
        id: 3,
        soil_classification_id: 1,
        soil_classification_name: "Q1",
        current_soil_cb_count: 555519,
        current_soil_ton_count: 867322,
        current_drive_count: 161,
        default_recycle_price_per_ton: 47211,
        is_allow_delete: false
      },
      {
        id: 4,
        soil_classification_id: 4,
        soil_classification_name: "B1",
        current_soil_cb_count: 143231,
        current_soil_ton_count: 641277,
        current_drive_count: 385,
        default_recycle_price_per_ton: 44410,
        is_allow_delete: false
      },
      {
        id: 5,
        soil_classification_id: 5,
        soil_classification_name: "Q1",
        current_soil_cb_count: 324245,
        current_soil_ton_count: 484872,
        current_drive_count: 352,
        default_recycle_price_per_ton: 97252,
        is_allow_delete: false
      }
    ],
    current_soil_cb_count: 2166891,
    current_soil_ton_count: 2934293,
    current_drive_count: 1013
  },
  {
    id: 2,
    engineering_classification_id: 2,
    engineering_classification: "相關拆除工程",
    control_number: "BCA4478",
    engineering_number: "(107)府交工字第36號",
    name: "出土工程2",
    location: "Togo Graniteville Gerald Court",
    host_vendor_id: 3,
    host_vendor: "Hutchinson",
    host_vendor_member_id: 5,
    host_vendor_member: "Sybil",
    host_vendor_member_phone: 8034562589,
    supervision_vendor_id: 2,
    supervision_vendor: "Leila",
    supervision_vendor_principal: "Tucker",
    supervision_vendor_principal_phone: 8955433327,
    contract_vendor_id: 3,
    contract_vendor: "Joanna",
    contract_vendor_principal: "陳曉明",
    contract_vendor_principal_phone: "072233333#123",
    contract_vendor_member_id: 1,
    contract_vendor_member: "Alford",
    contract_vendor_member_phone: 9105423340,
    transport_vendor_id: 3,
    transport_vendor: "Lourdes",
    transport_vendor_principal: "Shaffer",
    transport_vendor_principal_phone: 8315052041,
    default_transport_cost: 1790327,
    default_transport_count_by_cb: 12,
    default_transport_cost: 3500,
    driver_count: 30,
    soil_supply_classifications: [
      {
        id: 6,
        soil_classification_id: 1,
        soil_classification_name: "Q1",
        current_soil_cb_count: 231105,
        current_soil_ton_count: 795420,
        current_drive_count: 492,
        default_recycle_price_per_ton: 39912,
        is_allow_delete: true
      },
      {
        id: 7,
        soil_classification_id: 2,
        soil_classification_name: "T1",
        current_soil_cb_count: 905943,
        current_soil_ton_count: 633653,
        current_drive_count: 48,
        default_recycle_price_per_ton: 88015,
        is_allow_delete: true
      },
      {
        id: 8,
        soil_classification_id: 3,
        soil_classification_name: "B1",
        current_soil_cb_count: 731606,
        current_soil_ton_count: 787601,
        current_drive_count: 278,
        default_recycle_price_per_ton: 20668,
        is_allow_delete: true
      },
      {
        id: 9,
        soil_classification_id: 4,
        soil_classification_name: "B1",
        current_soil_cb_count: 918076,
        current_soil_ton_count: 543461,
        current_drive_count: 133,
        default_recycle_price_per_ton: 17375,
        is_allow_delete: true
      },
      {
        id: 10,
        soil_classification_id: 5,
        soil_classification_name: "T1",
        current_soil_cb_count: 374242,
        current_soil_ton_count: 448388,
        current_drive_count: 363,
        default_recycle_price_per_ton: 48109,
        is_allow_delete: true
      }
    ],
    current_soil_cb_count: 3160972,
    current_soil_ton_count: 3208523,
    current_drive_count: 1314
  },
  {
    id: 3,
    engineering_classification_id: 2,
    engineering_classification: "建築工程",
    control_number: "BCC4816",
    engineering_number: "(107)府交工字第37號",
    name: "出土工程3",
    location: "Papua New Guinea Brandermill Ralph Avenue",
    host_vendor_id: 3,
    host_vendor: "Greta",
    host_vendor_member_id: 2,
    host_vendor_member: "Willie",
    host_vendor_member_phone: 8754072683,
    supervision_vendor_id: 4,
    supervision_vendor: "Tara",
    supervision_vendor_principal: "Kaitlin",
    supervision_vendor_principal_phone: 9084312376,
    contract_vendor_id: 3,
    contract_vendor: "Evangeline",
    contract_vendor_principal: "許淑靜",
    contract_vendor_principal_phone: "092887333#1123",
    contract_vendor_member_id: 1,
    contract_vendor_member: "Parker",
    contract_vendor_member_phone: 8285713238,
    transport_vendor_id: 5,
    transport_vendor: "Ginger",
    transport_vendor_principal: "Christy",
    transport_vendor_principal_phone: 9945173803,
    default_transport_cost: 9271925,
    default_transport_count_by_cb: 12,
    default_transport_cost: 3500,
    driver_count: 500,
    soil_supply_classifications: [
      {
        id: 11,
        soil_classification_id: 2,
        soil_classification_name: "B1",
        current_soil_cb_count: 366760,
        current_soil_ton_count: 640120,
        current_drive_count: 249,
        default_recycle_price_per_ton: 31511,
        is_allow_delete: true
      },
      {
        id: 12,
        soil_classification_id: 1,
        soil_classification_name: "Q1",
        current_soil_cb_count: 335874,
        current_soil_ton_count: 561044,
        current_drive_count: 69,
        default_recycle_price_per_ton: 70208,
        is_allow_delete: true
      },
      {
        id: 13,
        soil_classification_id: 3,
        soil_classification_name: "Q1",
        current_soil_cb_count: 156366,
        current_soil_ton_count: 114718,
        current_drive_count: 195,
        default_recycle_price_per_ton: 79215,
        is_allow_delete: true
      },
      {
        id: 14,
        soil_classification_id: 4,
        soil_classification_name: "B1",
        current_soil_cb_count: 445530,
        current_soil_ton_count: 241092,
        current_drive_count: 53,
        default_recycle_price_per_ton: 39995,
        is_allow_delete: true
      },
      {
        id: 6,
        soil_classification_id: 5,
        soil_classification_name: "B1",
        current_soil_cb_count: 660326,
        current_soil_ton_count: 643992,
        current_drive_count: 88,
        default_recycle_price_per_ton: 81857,
        is_allow_delete: true
      }
    ],
    current_soil_cb_count: 1964856,
    current_soil_ton_count: 2200966,
    current_drive_count: 654
  },
  {
    id: 4,
    engineering_classification_id: 3,
    engineering_classification: "相關拆除工程",
    control_number: "CBA7571",
    engineering_number: "(107)府交工字第38號",
    name: "出土工程4",
    location: "Virgin Islands (US) Canby President Street",
    host_vendor_id: 4,
    host_vendor: "Clayton",
    host_vendor_member_id: 1,
    host_vendor_member: "Bailey",
    host_vendor_member_phone: 9884392657,
    supervision_vendor_id: 5,
    supervision_vendor: "Hardy",
    supervision_vendor_principal: "Leticia",
    supervision_vendor_principal_phone: 9374072957,
    contract_vendor_id: 3,
    contract_vendor: "Julianne",
    contract_vendor_principal: "陳大天",
    contract_vendor_principal_phone: "06223444#1123",
    contract_vendor_member_id: 4,
    contract_vendor_member: "Mullen",
    contract_vendor_member_phone: 9384792633,
    transport_vendor_id: 1,
    transport_vendor: "Aida",
    transport_vendor_principal: "Alston",
    transport_vendor_principal_phone: 9334633298,
    default_transport_cost: 7877841,
    default_transport_count_by_cb: 12,
    default_transport_cost: 3500,
    driver_count: 10,
    soil_supply_classifications: [
      {
        id: 15,
        soil_classification_id: 1,
        soil_classification_name: "Q1",
        current_soil_cb_count: 840905,
        current_soil_ton_count: 891524,
        current_drive_count: 10,
        default_recycle_price_per_ton: 95177,
        is_allow_delete: true
      },
      {
        id: 16,
        soil_classification_id: 2,
        soil_classification_name: "Q1",
        current_soil_cb_count: 945160,
        current_soil_ton_count: 231136,
        current_drive_count: 440,
        default_recycle_price_per_ton: 92721,
        is_allow_delete: true
      },
      {
        id: 17,
        soil_classification_id: 3,
        soil_classification_name: "T1",
        current_soil_cb_count: 357627,
        current_soil_ton_count: 462229,
        current_drive_count: 497,
        default_recycle_price_per_ton: 62700,
        is_allow_delete: true
      },
      {
        id: 18,
        soil_classification_id: 4,
        soil_classification_name: "Q1",
        current_soil_cb_count: 932113,
        current_soil_ton_count: 905296,
        current_drive_count: 216,
        default_recycle_price_per_ton: 74395,
        is_allow_delete: true
      },
      {
        id: 19,
        soil_classification_id: 5,
        soil_classification_name: "B1",
        current_soil_cb_count: 143500,
        current_soil_ton_count: 252726,
        current_drive_count: 490,
        default_recycle_price_per_ton: 23018,
        is_allow_delete: true
      }
    ],
    current_soil_cb_count: 3219305,
    current_soil_ton_count: 2742911,
    current_drive_count: 1653
  },
  {
    id: 5,
    engineering_classification_id: 2,
    engineering_classification: "相關拆除工程",
    control_number: "BCB3927",
    engineering_number: "(107)府交工字第39號",
    name: "出土工程5",
    location: "Guinea Vicksburg Oxford Street",
    host_vendor_id: 3,
    host_vendor: "Ola",
    host_vendor_member_id: 4,
    host_vendor_member: "Frye",
    host_vendor_member_phone: 8925742029,
    supervision_vendor_id: 1,
    supervision_vendor: "Herring",
    supervision_vendor_principal: "Janet",
    supervision_vendor_principal_phone: 9195473265,
    contract_vendor_id: 1,
    contract_vendor: "Landry",
    contract_vendor_principal: "小粉紅",
    contract_vendor_principal_phone: "07233311",
    contract_vendor_member_id: 2,
    contract_vendor_member: "Carmela",
    contract_vendor_member_phone: 9415273421,
    transport_vendor_id: 4,
    transport_vendor: "John",
    transport_vendor_principal: "Juarez",
    transport_vendor_principal_phone: 8704812550,
    default_transport_cost: 2617262,
    default_transport_count_by_cb: 12,
    default_transport_cost: 3500,
    driver_count: 0,
    soil_supply_classifications: [],
    current_soil_cb_count: 2745343,
    current_soil_ton_count: 2283310,
    current_drive_count: 1470
  }
];

export const soilSupplyEngineerings = {
  soilSupplyEngineeringsGetAll: {
    total: soilSupplyEngineeringsGetAll.length,
    items: soilSupplyEngineeringsGetAll
  },
  soilSupplyEngineeringsEdit(id) {
    return soilSupplyEngineeringsGetAll.find(item => item.id === id);
  },
  soilSupplyEngineeringsGetList: soilSupplyEngineeringsGetAll
};
