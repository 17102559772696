import { vendorConstants } from "../constants";

const initState = {
  query: {},
  list: {}
};

export const vendors = (state = initState, action) => {
  switch (action.type) {
    case vendorConstants.GET_ALL_REQUEST:
      return {
        ...state,
        query: {
          loading: true
        }
      };
    case vendorConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        query: {
          queryCondition: action.queryCondition,
          total: action.total,
          queryResult: action.items
        }
      };
    case vendorConstants.GET_ALL_FAILURE:
      return {
        ...state,
        query: {
          queryFail: action.error
        }
      };
    case vendorConstants.RESET_ALL_SUCCESS:
      return {
        ...state,
        query: {}
      };
    case vendorConstants.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          loading: true,
          listItems: null,
          getListFail: null
        }
      };
    case vendorConstants.GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          loading: false,
          listItems: action.vendors,
          getListFail: null
        }
      };
    case vendorConstants.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          loading: false,
          listItems: null,
          getListFail: action.error
        }
      };
    case vendorConstants.RESET_LIST_SUCCESS:
      return {
        ...state,
        list: {}
      };
    default:
      return state;
  }
};
