import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { history, secureStroge, storgeKeys, router } from "./utils";
import Loadable from "react-loadable";
import Loading from "./containers/Loading";

const Layout = Loadable({
  loader: () => import("./layouts"),
  loading: Loading
});

const LoginLayout = Loadable({
  loader: () => import("./layouts/Login"),
  loading: Loading
});

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      secureStroge.get(storgeKeys.userCredential) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/Login", state: { from: props.location } }}
        />
      )
    }
  />
);

const routes = (
  <Router history={history}>
    <div>
      <Switch>
        <Route strict path="/Login" component={LoginLayout} />
        <ProtectedRoute strict path="/" component={Layout} />
      </Switch>
    </div>
  </Router>
);

export default routes;
