import { ajax } from '../utils/ajax'

export const roleService = {
    /**
     * Send Get Roles Info Ajax Request.
     * @param {object} condition - The condition of query.
     * @description For search grid's result.
     */
    getAll: (condition) => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/roles`,
            {
                params: condition
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
    /**
     * Get Roles List.
     * @description For dropdownlist.
     */
    getList: () => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/roles/list`)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
    /**
     * Send Get Role Info Ajax Request.
     * @param {string} id - The id of role.
     */
    get: (id) => {
        return ajax.get(`${process.env.REACT_APP_API_URL}/roles/${id}`)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.data;
            });
    },
    /**
     * Create Role.
     * @param {object} info - The info of role and permissions.
     */
    create: (info) => {
        return ajax.post(`${process.env.REACT_APP_API_URL}/roles`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...info
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
    /**
     * Update Role.
     * @param {string} id - The id of role.
     * @param {object} info - The info of role and permissions.
     */
    update: (id, info) => {
        return ajax.put(`${process.env.REACT_APP_API_URL}/roles/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...info
                })
            })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    },
    /**
     * Delete Role.
     * @param {string} id - The id of role.
     */
    delete: (id) => {
        return ajax.delete(`${process.env.REACT_APP_API_URL}/roles/${id}`)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                if (res.status !== 200) {
                    return Promise.reject(res.message);
                }
                return res.message;
            });
    }
};