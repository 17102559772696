import { SMSVerificationConstants } from "../constants";
import { SMSVerificationService } from "../services";
import { noticeTypes, showNotification } from "../utils";
import { message as messager } from "antd";

/**
 * Purpose type enums of SMS Verification.
 */
export const purposeTypes = Object.freeze({
  THIRDPARTY_REGISTER: Symbol("thirdparty_register"),
  FORGET_PASSWORD: Symbol("forget_password"),
  EDIT_TELEPHONE: Symbol("edit_telephone"),
  EDIT_BACKUP_TELEPHONE: Symbol("edit_backup_telephone")
});

/**
 * Messager type enums.
 */
export const messagerTypes = Object.freeze({
  MESSAGE: Symbol("message"),
  NOTIFICATION: Symbol("notification")
});

export const SMSVerificationActions = {
  /**
   * Send SMS Verification Info.
   * @param {purposeTypes} purpose - The purpose of SMS verification.
   * @param {string} email - The email of recipient.
   * @param {messagerTypes} messagerType - The type of messager.
   */
  send(purpose, email, messagerType) {
    return dispatch => {
      dispatch({
        type: SMSVerificationConstants.SEND_REQUEST
      });
      SMSVerificationService.send(purpose.description, email).then(
        response => {
          const {
            status,
            message,
            data: { remaining_seconds }
          } = response;
          dispatch({
            type: SMSVerificationConstants.SEND_SUCCESS,
            seconds: remaining_seconds ? remaining_seconds : 0
          });
          SMSVerificationActions._showMessage(messagerType, status, message);
        },
        error => {
          dispatch({
            type: SMSVerificationConstants.SEND_FAILURE
          });
          SMSVerificationActions._showErrorMessage(messagerType, error);
        }
      );
    };
  },
  /**
   * Show Message
   * @param {messagerTypes} type - The type of messager.
   * @param {number} status - The type of messager.
   * @param {string} message - The message.
   */
  _showMessage(type, status, message) {
    switch (type) {
      case messagerTypes.NOTIFICATION:
        showNotification(
          status === 200 ? noticeTypes.SUCCESS : noticeTypes.WARNING,
          status === 200 ? "發送驗證電子信件成功" : "提醒您",
          message
        );
        break;
      default:
        if (status === 200) {
          messager.success(`發送驗證電子信件成功：${message}`);
        } else {
          messager.warning(message);
        }
        break;
    }
  },
  /**
   * Show Error Message
   * @param {messagerTypes} type - The type of messager.
   * @param {string} message - The message.
   */
  _showErrorMessage(type, message) {
    switch (type) {
      case messagerTypes.NOTIFICATION:
        showNotification(noticeTypes.ERROR, "發送電子信件驗證碼失敗：", message);
        break;
      default:
        messager.error(`發送電子信件驗證碼失敗：${message}`);
        break;
    }
  },
  /**
   * Reset SMS Verification Info
   */
  reset() {
    return {
      type: SMSVerificationConstants.RESET_SUCCESS
    };
  }
};
