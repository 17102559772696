import { ajax } from "../utils/ajax";

const FROM = "web.admin";

export const userService = {
  /**
   * Send Login Ajax Request.
   * @param {string} email - The email of user.
   * @param {string} password - The password of user.
   */
  login: (email, password) => {
    return ajax
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: password,
          from: FROM
        }),
        isWithToken: false
      })
      .then(response => {
        // Tip: 客製驗證 statusCode 可在此例外處理（優先順序在共用 statusCode 驗證後）
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Send Third-party Login Ajax Request.
   * @param {object} info - The info of login.
   */
  thirdpartyLogin: (info, token) => {
    return ajax
      .post(`${process.env.REACT_APP_API_URL}/auth/thirdparty-login`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info,
          recaptcha_token: token,
          from: FROM
        }),
        isWithToken: false
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 205) {
          return Promise.reject(res.message);
        }
        return res;
      });
  },
  /**
   * Send Third-party Register Ajax Request.
   * @param {object} info - The info of register.
   */
  thirdpartyRegister: info => {
    return ajax
      .post(`${process.env.REACT_APP_API_URL}/auth/thirdparty-register`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info,
          from: FROM
        }),
        isWithToken: false
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Send Check User's Credentials Ajax Request.
   * @param {string} uuid - The uuid of user.
   */
  check: uuid => {
    return ajax
      .post(`${process.env.REACT_APP_API_URL}/auth/authenticate`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          uuid: uuid,
          from: FROM
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res);
        }
        return res.data;
      });
  },
  /**
   * Send Get Users Info Ajax Request.
   * @param {object} condition - The condition of query.
   * @description For search grid's result.
   */
  getAll: condition => {
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        params: {
          ...condition
        }
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        // TODO: FOR TEST
        // if(Math.random() > 0.5) {
        //     return Promise.reject(`test`);
        // }
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Get Users List.
   * @param {object} condition - The condition of query.
   * @description For dropdownlist.
   */
  getList: condition => {
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/users/list`, {
        params: {
          ...condition
        }
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Get Contact User List.
   * @param {object} condition - The condition of query.
   * @description For dropdownlist.
   */
  getContactUserList: condition => {
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/users/contact/list`, {
        params: {
          ...condition
        }
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Send Get User Info Ajax Request.
   * @param {string} uuid - The uuid of user.
   */
  get: uuid => {
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/users/${uuid}`)
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Send Get User's Profile Info Ajax Request.
   */
  getProfile: () => {
    return ajax
      .get(`${process.env.REACT_APP_API_URL}/users/personal/profile`)
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.data;
      });
  },
  /**
   * Create User.
   * @param {object} info - The info of user.
   */
  create: info => {
    return ajax
      .post(`${process.env.REACT_APP_API_URL}/users/`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Update User.
   * @param {string} uuid - The uuid of user.
   * @param {object} info - The info of user.
   */
  update: (uuid, info) => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/${uuid}`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Update User's Profile.
   * @param {object} info - The info of user.
   */
  updateProfile: info => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/personal/profile`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res;
      });
  },
  /**
   * Send Delete User Ajax Request.
   * @param {string} uuid - The uuid of user.
   */
  delete: uuid => {
    return ajax
      .delete(`${process.env.REACT_APP_API_URL}/users/${uuid}`)
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Send Switch User's Enable State Ajax Request.
   * @param {string} uuid - The uuid of user.
   * @param {bool} isEnable - The specify enable status.
   */
  enable: (uuid, isEnable) => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/${uuid}/enable`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          is_enabled: isEnable
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Send Set User's Telephone Ajax Request.
   * @param {string} uuid - The uuid of user.
   * @param {string} telephone - The telephone of user.
   */
  setTelephone: (uuid, telephone) => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/${uuid}/telephone`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          new_telephone: telephone
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Send Set Personal Telephone Ajax Request.
   * @param {object} info - The info of telephone.
   */
  setPersonalTelephone: info => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/personal/telephone`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res;
      });
  },
  /**
   * Send Set Personal Backup Telephone Ajax Request.
   * @param {object} info - The info of backup telephone.
   */
  setPersonalBackupTelephone: info => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/personal/backup-telephone`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res;
      });
  },
  /**
   * Send Delete Personal Backup Telephone Ajax Request.
   */
  deletePersonalBackupTelephone: () => {
    return ajax
      .delete(`${process.env.REACT_APP_API_URL}/users/personal/backup-telephone`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Set User Password
   * @param {object} info - The info of user's password.
   */
  setPassword: info => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/personal/update-password`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Reset User Password
   * @param {object} info - The info of user's new password.
   */
  setNewPassword: info => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/auth/forget-password`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  },
  /**
   * Set User Avatar
   * @param {string} imageBase64 - The user's avatar base64 string.
   */
  setAvatar: imageBase64 => {
    // TODO: 測試錯誤用
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         reject('test');
    //     }, 3000);
    // });
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/personal/headshot`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          headshot: imageBase64
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res;
      });
  },
  /**
   * Force Change User Password
   * @param {string} uuid - The uuid of user
   * @param {object} info - The info of new password
   */
  forceChangePassword: (uuid, info) => {
    return ajax
      .put(`${process.env.REACT_APP_API_URL}/users/${uuid}/force-change-password`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.status !== 200) {
          return Promise.reject(res.message);
        }
        return res.message;
      });
  }
};
