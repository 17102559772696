import { message } from 'antd';
import { msgTypes, showModal } from '../utils';
import { moduleConstants } from '../constants';
import { moduleService } from '../services';
import { maskActions } from './mask.actions'
import { reduce } from 'lodash';

export const moduleActions = {
    /**
     * Get Modules Info.
     * @param {object} condition - The condition of query.
     * @description For search grid's result.
     */
    getAll(condition) {
        return dispatch => {
            dispatch({
                type: moduleConstants.GET_ALL_REQUEST
            });
            moduleService.getAll(condition)
                .then(
                    (modules) => {
                        dispatch({
                            type: moduleConstants.GET_ALL_SUCCESS,
                            queryCondition: { ...condition },
                            modules
                        });
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.GET_ALL_FAILURE,
                            error: '讀取模組查詢結果失敗：' + error
                        });
                    }
                )
        }
    },
    /**
     * Reset Modules Info
     */
    resetAll() {
        return {
            type: moduleConstants.RESET_ALL_SUCCESS,
        };
    },
    /**
     * Get Modules List.
     * @param {object} condition - The condition of query.
     * @description For dropdownlist.
     */
    getList(condition) {
        return dispatch => {
            dispatch({
                type: moduleConstants.GET_LIST_REQUEST
            });
            moduleService.getList(condition)
                .then(
                    (modules) => {
                        dispatch({
                            type: moduleConstants.GET_LIST_SUCCESS,
                            modules
                        });
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.GET_LIST_FAILURE,
                            error: '取得模組清單結果失敗：' + error
                        });
                    }
                )
        }
    },
    /**
     * Reset Modules List Info
     */
    resetList() {
        return {
            type: moduleConstants.RESET_LIST_SUCCESS,
        };
    },
    /**
     * Get Edited Module Info.
     * @param {string} id - The id of module.
     * @description For module edit.
     */
    getEdited(id) {
        return dispatch => {
            dispatch(maskActions.toggleMask(true));
            dispatch({
                type: moduleConstants.GET_EDIT_REQUEST
            });
            moduleService.get(id)
                .then(
                    (module) => {
                        let transModule = reduce(module, function (memo, value, key) {
                            memo[key] = { 'value': value };
                            return memo;
                        }, {});
                        dispatch({
                            type: moduleConstants.GET_EDIT_SUCCESS,
                            module: transModule
                        });
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.GET_EDIT_FAILURE,
                            error: '讀取模組資料失敗：' + error
                        });
                    }
                ).then(
                    () => {
                        dispatch(maskActions.toggleMask(false));
                    }
                );
        }
    },
    /**
     * Refresh Edited Module Info
     * @param {object} fields - The info of module.
     * @description Support module edit.
     */
    refreshEdited(fields) {
        return {
            type: moduleConstants.REFRESH_EDIT_SUCCESS,
            refreshEditInfo: fields
        };
    },
    /**
     * Reset Module Info
     */
    reset() {
        return {
            type: moduleConstants.RESET_SUCCESS,
        };
    },
    /**
     * Create Module Category.
     * @param {object} info - The info of module category.
     * @param {requestCallback} cbSuccess - The callback of success.
     */
    createModuleCategory(info, cbSuccess) {
        return (dispatch, getState) => {
            dispatch({
                type: moduleConstants.SET_EDITED_REQUEST
            });
            moduleService.createModuleCategory(info)
                .then(
                    (response) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_SUCCESS
                        });
                        if (cbSuccess && typeof cbSuccess === 'function') {
                            cbSuccess();
                        }
                        const modulesState = getState().modules;
                        dispatch(moduleActions.getAll(modulesState && modulesState.queryCondition ? modulesState.queryCondition : null));
                        message.success(response);
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_FAILURE,
                            error: `新增模組分類資料失敗：${error}`
                        });
                    }
                );
        }
    },
    /**
     * Update Module Category.
     * @param {string} id - The id of module category.
     * @param {object} info - The info of module category.
     * @param {requestCallback} cbSuccess - The callback of success.
     */
    updateModuleCategory(id, info, cbSuccess) {
        return (dispatch, getState) => {
            dispatch({
                type: moduleConstants.SET_EDITED_REQUEST
            });
            moduleService.updateModuleCategory(id, info)
                .then(
                    (response) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_SUCCESS
                        });
                        if (cbSuccess && typeof cbSuccess === 'function') {
                            cbSuccess();
                        }
                        const modulesState = getState().modules;
                        dispatch(moduleActions.getAll(modulesState && modulesState.queryCondition ? modulesState.queryCondition : null));
                        message.success(response);
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_FAILURE,
                            error: `新增模組分類資料失敗：${error}`
                        });
                    }
                );
        }
    },
    /**
     * Create Module.
     * @param {object} info - The info of module.
     * @param {requestCallback} cbSuccess - The callback of success.
     */
    createModule(info, cbSuccess) {
        return (dispatch, getState) => {
            dispatch({
                type: moduleConstants.SET_EDITED_REQUEST
            });
            moduleService.createModule(info)
                .then(
                    (response) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_SUCCESS
                        });
                        if (cbSuccess && typeof cbSuccess === 'function') {
                            cbSuccess();
                        }
                        const modulesState = getState().modules;
                        dispatch(moduleActions.getAll(modulesState && modulesState.queryCondition ? modulesState.queryCondition : null));
                        message.success(response);
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_FAILURE,
                            error: `新增模組資料失敗：${error}`
                        });
                    }
                );
        }
    },
    /**
     * Update Module.
     * @param {string} id - The id of module.
     * @param {object} info - The info of module.
     * @param {requestCallback} cbSuccess - The callback of success.
     */
    updateModule(id, info, cbSuccess) {
        return (dispatch, getState) => {
            dispatch({
                type: moduleConstants.SET_EDITED_REQUEST
            });
            moduleService.updateModule(id, info)
                .then(
                    (response) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_SUCCESS
                        });
                        if (cbSuccess && typeof cbSuccess === 'function') {
                            cbSuccess();
                        }
                        const modulesState = getState().modules;
                        dispatch(moduleActions.getAll(modulesState && modulesState.queryCondition ? modulesState.queryCondition : null));
                        message.success(response);
                    },
                    (error) => {
                        dispatch({
                            type: moduleConstants.SET_EDITED_FAILURE,
                            error: `修改模組資料失敗：${error}`
                        });
                    }
                );
        }
    },
    /**
     * Delete Module.
     * @param {string} id - The id of module.
     */
    delete(id) {
        return (dispatch, getState) => {
            dispatch(maskActions.toggleMask(true));
            moduleService.delete(id)
                .then(
                    (response) => {
                        showModal(msgTypes.SUCCESS, '通知', response, () => {
                            const modulesState = getState().modules;
                            dispatch(moduleActions.getAll(modulesState && modulesState.queryCondition ? modulesState.queryCondition : null));
                            message.success(response);
                        });
                    },
                    (error) => {
                        showModal(msgTypes.ERROR, '錯誤', error);
                    }
                ).then(
                    () => {
                        dispatch(maskActions.toggleMask(false));
                    }
                );
        }
    },
};