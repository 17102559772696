import { ajax } from "../utils/ajax";
import { soilTransportRecords } from "../.fakeDatas/soilTransportRecord";
import filePath from "../.fakeDatas/運土紀錄表.xlsx";
import { saveAs } from "file-saver";

const timeoutPromise = (data, timeout, isReject = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      isReject ? reject(data) : resolve(data);
    }, timeout);
  });
};

export const soilTransportRecordService = {
  /**
   * Send Get All Soil Transport Record Ajax Request.
   * @param {object} condition - The condition of query.
   */
  getAll(condition) {
    // return timeoutPromise(soilTransportRecords.soilTransportRecordsGetAll, 1000);
    // return timeoutPromise("查詢失敗", 1000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/soil-transport-records`, {
        params: {
          ...condition
        }
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Get Soil Transport Record Info Ajax Request.
   * @param {object} condition - The condition of query.
   * @param {string} id - The id of soil transport record.
   */
  get(id, condition) {
    // return timeoutPromise(soilTransportRecords.soilTransportRecordsEdit(id), 1000);
    // return timeoutPromise("讀取運土紀錄單失敗", 1000, true);

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/soil-transport-records/${id}`, {
        params: {
          ...condition
        }
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.data));
  },
  /**
   * Send Create Soil Transport Record Ajax Request.
   * @param {object} info - The info of soil transport record.
   */
  create(info) {
    // console.log(`create get info`, info);
    // return timeoutPromise("新增成功", 1000);

    return ajax
      .post(`${process.env.REACT_APP_API_URL}/soil-transport-records`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Update Soil Transport Record Ajax Request.
   * @param {string} id - The id of soil transport record.
   * @param {object} info - The info of soil transport record.
   */
  update(id, info) {
    // console.log(`update get info`, info);
    // return timeoutPromise("修改成功", 1000);

    return ajax
      .put(`${process.env.REACT_APP_API_URL}/soil-transport-records/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...info
        })
      })
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Delete Soil Transport Record Ajax Request.
   * @param {string} id - The id of soil transport record.
   */
  delete(id) {
    // return timeoutPromise("刪除成功", 1000);

    return ajax
      .delete(`${process.env.REACT_APP_API_URL}/soil-transport-records/${id}`)
      .then(response => response.json())
      .then(res => (res.status !== 200 ? Promise.reject(res.message) : res.message));
  },
  /**
   * Send Export Soil Transport Records Request.
   * @param {string} projectId - The id of soil charge engineering project.
   * @param {object} condition - The condition of query.
   */
  export(projectId, condition) {
    // return timeoutPromise("匯出成功", 1000);
    // return fetch(filePath)
    //   .then(res => res.blob())
    //   .then(data => saveAs(data, "運土紀錄表.xlsx"));

    return ajax
      .get(`${process.env.REACT_APP_API_URL}/soil-transport-records/export`, {
        params: condition
      })
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        saveAs(blob, "運土紀錄表.xlsx");
      });
  }
};
