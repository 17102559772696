import { soilSupplyEngineeringService } from "../services";
import { soilSupplyEngineeringConstants } from "../constants";
import { maskActions } from ".";
import { reduce } from "lodash";
import { msgTypes, showModal } from "../utils";
import { message } from "antd";
import moment from "moment";

export const soilSupplyEngineeringActions = {
  /**
   * Get Soil Supply Engineering Info.
   * @param {string} projectId - The id of soil charge engineering project.
   * @description For search grid's result.
   */
  getAll(projectId, condition) {
    return dispatch => {
      dispatch({
        type: soilSupplyEngineeringConstants.GET_ALL_REQUEST
      });
      soilSupplyEngineeringService.getAll(projectId, condition).then(
        response => {
          dispatch({
            type: soilSupplyEngineeringConstants.GET_ALL_SUCCESS,
            projectId,
            queryCondition: { ...condition },
            ...response
          });
        },
        error => {
          dispatch({
            type: soilSupplyEngineeringConstants.GET_ALL_FAILURE,
            error: "讀取出土工程查詢結果失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Soil Supply Engineering Info
   */
  resetAll() {
    return {
      type: soilSupplyEngineeringConstants.RESET_ALL_SUCCESS
    };
  },
  /**
   * Get Soil Supply Engineering List.
   * @param {string} projectId - The id of soil charge engineering project.
   * @description For dropdownlist.
   */
  getList(projectId) {
    return dispatch => {
      dispatch({
        type: soilSupplyEngineeringConstants.GET_LIST_REQUEST
      });
      soilSupplyEngineeringService.getList(projectId).then(
        soilSupplyEngineerings => {
          dispatch({
            type: soilSupplyEngineeringConstants.GET_LIST_SUCCESS,
            soilSupplyEngineerings
          });
        },
        error => {
          dispatch({
            type: soilSupplyEngineeringConstants.GET_LIST_FAILURE,
            error: "讀取出土工程選單失敗：" + error
          });
        }
      );
    };
  },
  /**
   * Reset Soil Supply Engineering List Info
   */
  resetList() {
    return {
      type: soilSupplyEngineeringConstants.RESET_LIST_SUCCESS
    };
  },
  /**
   * Get Edited Soil Supply Engineering Info.
   * @param {string} id - The id of soil supply engineering.
   * @description For soil supply engineering edit.
   */
  getEdited(id) {
    return dispatch => {
      dispatch({ type: soilSupplyEngineeringConstants.GET_EDITED_REQUEST });
      soilSupplyEngineeringService
        .get(id)
        .then(
          soilSupplyEngineering => {
            let transSoilSupplyEngineering = reduce(
              soilSupplyEngineering,
              function (memo, value, key) {
                memo[key] = { value: value };
                return memo;
              },
              {}
            );

            const { default_transport_cost, soil_supply_classifications = [] } =
              soilSupplyEngineering;

            transSoilSupplyEngineering.transport_cost = {
              value: default_transport_cost
            };

            transSoilSupplyEngineering.soil_supply_classifications =
              soil_supply_classifications.map(classification => ({
                ...classification,
                default_recycle_price_per_ton: {
                  value: classification.default_recycle_price_per_ton
                },
                soil_classification_id: { value: classification.soil_classification_id },
                // 是否已被紀錄參考
                is_allow_delete: !classification.total_transport_record_count
              }));

            dispatch({
              type: soilSupplyEngineeringConstants.GET_EDITED_SUCCESS,
              soilSupplyEngineering: transSoilSupplyEngineering
            });
          },
          error => {
            dispatch({
              type: soilSupplyEngineeringConstants.GET_EDITED_FAILURE,
              error
            });
          }
        );
    };
  },
  /**
   * Remove Soil Classification Data.
   * @param {string} memberId - The id of member.
   */
  removeSoilClassification(soilClassificationId) {
    return {
      type: soilSupplyEngineeringConstants.REMOVE_SOIL_CLASSIFICATION,
      soilClassificationId
    };
  },
  /**
   * Add Soil Classification Data.
   */
  addSoilClassification() {
    return {
      type: soilSupplyEngineeringConstants.ADD_SOIL_CLASSIFICATION
    };
  },
  /**
   * Create Soil Supply Engineering.
   * @param {object} info - The info of soil supply engineering.
   * @param {function} cbSuccess - The callback of success.
   */
  create(info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: soilSupplyEngineeringConstants.SET_EDITED_REQUEST
      });
      soilSupplyEngineeringService.create(info).then(
        response => {
          dispatch({
            type: soilSupplyEngineeringConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          soilSupplyEngineeringActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          const errorMessage = `新增出土工程資料失敗：${error}`;
          message.error(errorMessage);
          dispatch({
            type: soilSupplyEngineeringConstants.SET_EDITED_FAILURE,
            error: errorMessage
          });
        }
      );
    };
  },
  /**
   * Update Soil Supply Engineering.
   * @param {string} id - The id of soil supply engineering.
   * @param {object} info - The info of soil supply engineering.
   * @param {function} cbSuccess - The callback of success.
   */
  update(id, info, cbSuccess) {
    return (dispatch, getState) => {
      dispatch({
        type: soilSupplyEngineeringConstants.SET_EDITED_REQUEST
      });
      soilSupplyEngineeringService.update(id, info).then(
        response => {
          dispatch({
            type: soilSupplyEngineeringConstants.SET_EDITED_SUCCESS
          });
          if (cbSuccess && typeof cbSuccess === "function") {
            cbSuccess();
          }
          soilSupplyEngineeringActions.reloadGetAll(dispatch, getState);
          message.success(response);
        },
        error => {
          const errorMessage = `修改出土工程資料失敗：${error}`;
          message.error(errorMessage);
          dispatch({
            type: soilSupplyEngineeringConstants.SET_EDITED_FAILURE,
            error: errorMessage
          });
        }
      );
    };
  },
  /**
   * Delete Soil Supply Engineering.
   * @param {string} id - The id of soil supply engineering.
   * @param {function} cbSuccess - The callback of success.
   */
  delete(id, cbSuccess) {
    return (dispatch, getState) => {
      dispatch(maskActions.toggleMask(true));
      soilSupplyEngineeringService
        .delete(id)
        .then(
          response => {
            showModal(msgTypes.SUCCESS, "通知", response, () => {
              soilSupplyEngineeringActions.reloadGetAll(dispatch, getState);

              if (cbSuccess && typeof cbSuccess === "function") {
                cbSuccess();
              }
            });
          },
          error => {
            showModal(msgTypes.ERROR, "錯誤", error);
          }
        )
        .then(() => {
          dispatch(maskActions.toggleMask(false));
        });
    };
  },
  /**
   * Refresh Edited Soil Supply Engineering Info.
   * @param {object} fields - The info of soil supply engineering.
   * @description Support soil supply engineering edit.
   */
  refreshEdited(fields) {
    return {
      type: soilSupplyEngineeringConstants.REFRESH_EDITED_SUCCESS,
      refreshEditInfo: fields
    };
  },
  /**
   * Reset Soil Supply Engineering Info.
   */
  reset() {
    return {
      type: soilSupplyEngineeringConstants.RESET_EDITED_SUCCESS
    };
  },
  reloadGetAll(dispatch, getState) {
    const { query } = getState().soilSupplyEngineerings;
    dispatch(
      soilSupplyEngineeringActions.getAll(
        query && query.projectId ? query.projectId : null,
        query && query.queryCondition ? query.queryCondition : null
      )
    );
  }
};
