import { moduleConstants } from '../constants';

export const module = (state = {}, action) => {
    switch (action.type) {
        case moduleConstants.GET_EDIT_REQUEST:
            return {
                loading: true
            };
        case moduleConstants.GET_EDIT_SUCCESS:
            return {
                loading: false,
                editInfo: action.module
            };
        case moduleConstants.GET_EDIT_FAILURE:
            return {
                loading: false,
                loadFail: action.error
            };
        case moduleConstants.SET_EDITED_REQUEST:
            return {
                ...state,
                processing: true
            };
        case moduleConstants.SET_EDITED_FAILURE:
            return {
                ...state,
                processing: false,
                processFail: action.error
            };
        case moduleConstants.SET_EDITED_SUCCESS:
            return {
                ...state,
                processing: false,
                processFail: null
            };
        case moduleConstants.REFRESH_EDIT_SUCCESS:
            return {
                editInfo: {
                    ...state.editInfo,
                    ...action.refreshEditInfo,
                }
            };
        case moduleConstants.RESET_SUCCESS:
            return {};
        default:
            return state
    }
}