import { matchPath, generatePath } from "react-router-dom";
import { secureStroge, storgeKeys } from "./";
import { get, invert, keys, findLast, sortBy, find } from "lodash";

/**
 * 功能路由匹配表
 * @description 開發新功能時，自動加上對應頁面連結
 */
const routerMatcher = {
  UserManager: "/Users",
  CreateUser: "/Users/Register/",
  UpdateUser: "/Users/Set/:uuid",
  EditProfile: "/UserSetting",
  RoleManager: "/Roles",
  CreateRole: "/Roles/Create/",
  UpdateRole: "/Roles/Set/:id",
  ModuleManager: "/Modules",
  LogManager: "/Logs",
  VendorManager: "/Vendors",
  DriverRegistrationManager: "/DriverRegistrations",
  SoilChargeEngineeringProjectManager: "/SoilChargeEngineeringProjects",
  SoilSupplyEngineeringManager: "/SoilChargeEngineeringProjects/:projectId/SoilSupplyEngineerings",
  SoilTransportRecordManager: "/SoilTransportRecords",
  ReportManager: "/Reports"
};

export const router = {
  /**
   * 根據索引取得頁面對應位址
   * @param {key} string - 功能權限索引
   * @param {params} object - 網址參數
   * @description 與 ContentRouter 與 SliderLayout 的頁面連結有關
   */
  getPath(key, params) {
    if (key) {
      let path = get(routerMatcher, key);
      if (path) {
        return params ? generatePath(path, params) : path;
      }
    }
    return "/";
  },
  /**
   * 根據頁面實際位址取得索引
   * @param {path} string - 頁面實際位址
   * @description 與 ContentLayout 的麵包屑有關
   */
  getKey(path) {
    const invertRoutes = invert(routerMatcher);
    const routes = sortBy(keys(invertRoutes));
    const matchRoute = findLast(routes, route => {
      return matchPath(path, route);
    });
    return matchRoute ? get(invertRoutes, matchRoute) : null;
  },

  /**
   * 取得模組名稱
   * @param {key} string - 功能權限索引
   * @description 提供各模組取名稱用
   */
  getName(key) {
    const userCredential = secureStroge.get(storgeKeys.userCredential);
    const permission = find(userCredential.permissions, ["key", key]);
    return permission ? permission.name : null;
  }
};
