import { driverRegistrationConstants } from "../constants";

const initState = {
  query: {},
  list: {}
};

export const driverRegistrations = (state = initState, action) => {
  switch (action.type) {
    case driverRegistrationConstants.GET_ALL_REQUEST:
      return {
        ...state,
        query: {
          loading: true
        }
      };
    case driverRegistrationConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        query: {
          queryCondition: action.queryCondition,
          total: action.total,
          queryResult: action.items
        }
      };
    case driverRegistrationConstants.GET_ALL_FAILURE:
      return {
        ...state,
        query: {
          queryFail: action.error
        }
      };
    case driverRegistrationConstants.RESET_ALL_SUCCESS:
      return {
        ...state,
        query: {}
      };
    case driverRegistrationConstants.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          loading: true,
          listItems: null,
          getListFail: null
        }
      };
    case driverRegistrationConstants.GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          loading: false,
          listItems: action.driverRegistrations,
          getListFail: null
        }
      };
    case driverRegistrationConstants.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          loading: false,
          listItems: null,
          getListFail: action.error
        }
      };
    case driverRegistrationConstants.RESET_LIST_SUCCESS:
      return {
        ...state,
        list: {}
      };
    default:
      return state;
  }
};
